.PageComplectCollections-Collections {
    padding: 20px 40px 20px 40px;
    display: grid;
    grid-template-columns: repeat(3, calc((100% - 40px * 2) / 3));
    gap: 40px;
    height: 100%;
}
@media screen  and (max-width: 768px) {
    .PageComplectCollections-Collections {
        grid-template-columns: 50% 50%;
    }
}
@media screen  and (max-width: 480px) {
    .PageComplectCollections-Collections {
        grid-template-columns: 100%;
    }
}