.Hinges-Content{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
}
.Hinges-Section{
    width: 50%;
}
.Hinges-Title{
    margin-bottom: 8px;
}
