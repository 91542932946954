.TabsMenu {
    width: 100%;
    margin-bottom: 15px;
    display: block;
}
.TabsMenu-Tab {
    padding: 0 10px;
    display: inline-block;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border: 1px solid var(--color-gray);
    background-color: var(--color-light-gray);
}
.TabsMenu-Tab_active {
    background-color: var(--color-white);
    border-bottom: none;
}
.TabsMenu_view_default.TabsMenu_layout_horiz .TabsMenu-Tab_active::before {
    display: none;
}
.TabsMenu_layout_horiz .TabsMenu-Tab {
    margin-left: 2px;
}