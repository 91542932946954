.MenuBurger {
    cursor: pointer;
    transition-property: opacity, filter;
    transition-duration: 0.15s;
    transition-timing-function: linear;
    font: inherit;
    color: inherit;
    text-transform: none;
    background-color: transparent;
    border: 0;
    margin: 0;
    overflow: visible;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 100%;
}
.MenuBurger-Box {
    width: 20px;
    height: 19px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.MenuBurger-Inner {
    display: block;
    width: 20px;
    height: 3px;
    background-color: var(--color-light-black);
    border-radius: 4px;
    transition-property: transform;
}
.MenuBurger-Inner_hover {
    background-color: var(--color-black);
}
.MenuBurger-Inner_active {
    width: 24px;
}
.MenuBurger-Inner:nth-child(1) {
    top: 0;
    transition-duration: 0.275s;
    transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
.MenuBurger-Inner:nth-child(2) {
    top: 8px;
    transition: opacity 0.125s 0.275s ease;
}
.MenuBurger-Inner:nth-child(3) {
    top: 16px;
    transition: transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
.MenuBurger-Inner_active:nth-child(1) {
    transform: translate3d(-2px, 8px, 0) rotate(135deg);
    transition-delay: 0.075s;
}
.MenuBurger-Inner_active:nth-child(2) {
    transition-delay: 0s;
    opacity: 0;
}
.MenuBurger-Inner_active:nth-child(3) {
    transform: translate3d(-2px, -8px, 0) rotate(-135deg);
    transition-delay: 0.075s;
}