.cnWizardMoveObject {
    position: fixed;
    top: 0;
    left: 0;
    width: 50px;
    height: 50px;
    border: 1px solid;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.cnWizardMoveObject-Image {
    width: 100%;
    height: auto;
}