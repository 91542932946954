.Pager {
    display: flex;
    padding: 10px 15px;
    column-gap: 10px;
}
.Pager-Button {
    padding: 8px;
}
.Pager-Button_type_first {
    margin-right: auto;
}
.Pager-Button_type_last {
    margin-left: auto;
}
