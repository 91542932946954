.WizardFlyMenu {
    position: absolute;
    z-index: 9;
    top: 120px;
    left: 50%;
    transform: translateX(-50%);
    background-color: var(--color-white);
    border: 1px solid var(--color-gray);
    border-radius: 8px;
    height: 40px;
    text-align: center;
}
.WizardFlyMenu-Content {
    position: relative;
}
.WizardFlyMenu-Drug {
    position: absolute;
    width: 16px;
    height: 16px;
    top: -8px;
    left: -8px;
    border-radius: 3px;
    background-color: var(--color-gray);
    cursor: move;
    padding: 3px;
}
.WizardFlyMenu-DrugIcon {
    width: 10px;
    height: 10px;
    display: flex;
    align-items: center;
    fill: var(--color-black)
}
.WizardFlyMenu-Actions {
    display: flex;
    height: 100%;
}
.WizardFlyMenu-Action {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 9px;
    cursor: pointer;
}
.WizardFlyMenu-Action_enable .Svg {
    fill: var(--color-red);
}
.WizardFlyMenu-Icon {
    width: 20px;
    height: 20px;
}
.WizardFlyMenu-Action_type_history {
    cursor: default;
}
.WizardFlyMenu-Action_type_history.WizardFlyMenu-Action_active {
    cursor: pointer;
}
.WizardFlyMenu-Icon_type_history {
    fill: var(--color-gray);
}
.WizardFlyMenu-Icon_type_history.WizardFlyMenu-Icon_active {
    fill: var(--color-red);
}