.Theme_root_default {
    --color-white: #ffffff;
    --color-light-gray: #f2f6fa;
    --color-gray: #ccd7e7;
    --color-light-black: #7b7e8d;
    --color-black: #353952;
    --color-light-orange: #efa33f;
    --color-orange: #ea8b19;
    --color-light-green: #6c9763;
    --color-green: #447e35;
    --color-blue: #37657e;
    --color-light-red: #e06753;
    --color-red: #d14522;
    --color-violet: #744a96;
    --spin-view-default-border-color: var(--color-red);
    --checkbox-size-m-width: 18px;
    --checkbox-size-m-height: 18px;
    --checkbox-size-m-tick-width: 18px;
    --checkbox-size-m-tick-height: 13px;
    --checkbox-view-default-fill-color-base: var(--color-white);
    --checkbox-view-default-fill-color-hovered: var(--color-light-green);
    --checkbox-view-default-fill-color-checked-base: var(--color-light-green);
    --checkbox-view-default-fill-color-checked-hovered: var(--color-light-green);
    --checkbox-view-default-outline-color: var(--color-white);
    --font-smaller: 12px;
    --font-small: 15px;
    --font-standard: 16px;
    --font-s-middle: 17px;
    --font-middle: 19px;
    --font-big: 21px;
    --font-bigger: 28px;
    --font-large: 30px;
    --font-larger: 50px;
    --font-largest: 60px;
}
