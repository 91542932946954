.PageCollections-FilterSort {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}
.PageCollections-Filter {
    position: relative;
    padding: 10px 15px;
}
.PageCollections-Button {
    font-size: 15px;
    font-weight: 400;
    border-width: 2px;
    border-style: solid;
    border-radius: 8px;
    padding: 5px;
    position: relative;
}
.PageCollections-Scroller {
    min-width: 155px;
    max-height: 785px;
    overflow: hidden;
    overflow-y: auto;
}
.PageCollections-Item {
    font-size: 15px;
    font-weight: 400;
    color: var(--color-light-black);
    padding: 5px 20px 5px 5px;
}
.PageCollections-Item_hover {
    cursor: pointer;
    background-color: var(--color-gray);
}
.PageCollections-Item_checked {
    background-color: var(--color-gray);
}
.PageCollections-Sort {
    margin-left: auto;
    display: flex;
    align-items: center;
    padding: 10px 15px;
}
.PageCollections-Text {
    font-size: 16px;
    font-weight: 400;
    color: var(--color-gray);
}
.PageCollections-Group {
    display: flex;
    margin: 6px 0 7px 15px;
}
.PageCollections-Group_hover {
    cursor: pointer;
}
.PageCollections-Label {
    font-size: 15px;
    font-weight: 400;
    color: var(--color-gray);
    text-transform: uppercase;
}
.PageCollections-Label_active {
    color: var(--color-red);
}
.PageCollections-Icons {
    display: flex;
    flex-direction: column;
    margin-left: 3px;
}
.PageCollections-Icon {
    fill: var(--color-black);
}
.PageCollections-Icon_active {
    fill: var(--color-red);
}
.PageCollections-Collections {
    padding: 20px 40px 20px 40px;
    display: grid;
    grid-template-columns: repeat(3, calc((100% - 40px * 2) / 3));
    gap: 40px;
    height: 100%;
}
@media screen  and (max-width: 768px) {
    .PageCollections-Collections {
        grid-template-columns: 50% 50%;
    }
}
@media screen  and (max-width: 480px) {
    .PageCollections-Collections {
        grid-template-columns: 100%;
    }
}