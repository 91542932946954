.Wizard-ContextMenuWrapper {
    transform: translateY(-30%);
    border: 2px solid var(--color-gray);
    border-radius: 8px;
    padding: 5px;
    background-color: var(--color-light-gray);
}
.Wizard-ContextMenuItem {
    position: relative;
    margin: 0;
    align-items: center;
    display: flex;
    justify-content: center;
    width: 35px;
    height: 35px;
    background: var(--color-light-gray);
    cursor: pointer;
    border-radius: 5px;
    padding: 8px;
}