.AccessoryPlankList {
    width: 100%;
    height: 100%;
}
.AccessoryPlankList-Header {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 20px;
}
.AccessoryPlankList-Title {
    width: 50%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: var(--font-middle);
    font-weight: bold;
}
.AccessoryPlankList-Search {
    width: 50%;
    position: relative;
    margin: 0;
    padding: 0 5px;
}
.AccessoryPlankList-SearchButton {
    width: 20px;
    height: 20px;
    position: absolute;
    right: 10px;
    top: 0;
    transform: translateY(50%);
    z-index: 3;
    border-radius: 50%;
    background: transparent;
    cursor: pointer;
}
.AccessoryPlankList-SearchText .Textinput-Control {
    padding-right: 25px;
}

.AccessoryPlankList-Wrapper {
    max-height: calc(100% - 50px);
    display: grid;
    grid-template-columns: repeat(4, calc((100% - 40px* 2) / 4));
    overflow-y: auto;
    overflow-x: hidden;
    padding: 20px;
    gap: 20px;
}

@media screen  and (max-width: 1400px) {
    .AccessoryPlankList-Wrapper {
        grid-template-columns: repeat(3, calc((100% - 40px* 2) / 3));
    }
}

@media screen  and (max-width: 1024px) {
    .AccessoryPlankList-Wrapper {
        grid-template-columns: 50% 50%;
    }
}
@media screen  and (max-width: 768px) {
    .AccessoryPlankList-Wrapper {
        grid-template-columns: 100%;
    }
}
.AccessoryPlankList-Item {
    max-height: 400px;
    min-height: 350px;
}
