.Messages {

}
.Messages-Item {
    background-color: var(--color-white);
    position: relative;
    padding: 10px 25px 10px 10px;
    border-radius: 8px;
    border: 1px solid;
    margin-top: 10px;
}
.Messages-Item_type_success {
    color: var(--color-green)
}
.Messages-Item_type_warning {
    color: var(--color-light-orange)
}
.Messages-Item_type_error {
    color: var(--color-red)
}
.Messages-Item_type_info {
    color: var(--color-black)
}
.Messages-Button {
    position: absolute;
    top: 5px;
    right: 5px;
    width: 16px;
    height: 16px;
    background-color: transparent;
    padding: 2px;
}