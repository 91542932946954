.UserObjectSelectList-Search {
    position: relative;
}
.UserObjectSelectList-Value {
    display: flex;
    column-gap: 15px;
    margin-bottom: 15px;
}
.UserObjectSelectList-SearchButton {
    position: absolute;
    top: 8px;
    right: 8px;
    z-index: 10;
}
.UserObjectSelectList-UserText {
    font-size: var(--font-s-middle);
    font-weight: bold;
    width: 100%;
}
.UserObjectSelectList-SearchResult {
    width: 100%;
    max-height: 250px;
    overflow-y: auto;
}
.UserObjectSelectList-SearchItem {
    padding: 5px 10px;
    font-weight: bold;
    cursor: pointer;
    margin-bottom: 3px;
    border-bottom: 1px solid var(--color-light-gray);
}
.UserObjectSelectList-SearchItem:hover {
    background-color: var(--color-light-gray);
}
