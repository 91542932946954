.MainMenu {
    position: absolute;
    top: 66px;
    left: -310px;
    min-height: 50px;
    background-color: var(--color-light-gray);
    border-radius: 8px;
    padding: 10px 10px 10px 18px;
    z-index: 1000;
    transition: all 0.2s linear;
    opacity: 0;
    width: 0;
    height: 0;
}
.MainMenu_visible {
    left: -8px;
    opacity: 1;
    width: 300px;
    height: auto;
}
.MainMenu-Item {
    border-bottom: 1px solid var(--color-white);
    padding: 10px;
}
.MainMenu-Item_type_innerLink {
    padding-left: 37px;
}
.MainMenu-Link {
    display: block;
    font-size: 16px;
    font-weight: 400;
}