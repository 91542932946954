.Wizard-SettingsMenu {
    position: absolute;
    top: 115px;
    right: -468px;
    z-index: 1002;
    padding-top: 0;
    bottom: 60px;
    width: 360px;
    min-height: 300px;
    background-color: var(--color-white);
    display: flex;
    align-items: center;
    flex-direction: column;
    border-radius: 8px;
    border: 2px solid var(--color-light-gray);
}
.Wizard-SettingsMenu_animated {
    transition: .4s;
}
.Wizard-SettingsMenu_visible {
    right: 70px;
}