.UserMenu {
    position: absolute;
    top: 66px;
    right: 15px;
    border-radius: 8px;
    background-color: var(--color-light-gray);
    min-height: 50px;
    color: var(--color-light-black);
    width: 0;
    height: 0;
    transition: all 0.2s linear;
    opacity: 0;
    display: none;
}
.UserMenu_visible {
    width: 200px;
    height: auto;
    opacity: 1;
    display: block;
}
.UserMenu-Item {
    margin: 5px 15px;
    padding-bottom: 8px;
    border-bottom: 1px solid var(--color-white);
    text-align: left;
}
.UserMenu-Link {
    display: block;
    font-size: 16px;
    font-weight: 400;
}
.UserMenu-Login {
    margin: 5px 15px;
    padding-bottom: 8px;
    display: block;
    font-size: 16px;
    font-weight: bold;
}