.Number {
    display: inline-block;
    position: relative;
}
.Number .Textinput-Control {
    height: 40px;
    padding: 0 10px;
}
.Number_bordered .Textinput-Control {
    border-radius: 8px;
    border: 2px solid var(--color-gray);
}
.Number_bkg_white .Textinput-Control {
    background-color: var(--color-white);
}
.Number_color_black .Textinput-Control {
    color: var(--color-black);
}
.Number_size_lg .Textinput-Control {
    font-size: var(--font-s-middle);
    font-weight: 400;
}
.Number_disabled .Textinput-Control {
    color: var(--color-gray);
}
.Number-Decrement,
.Number-Increment {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 4;
    cursor: pointer;
    user-select: none;
    pointer-events: none;
    touch-action: none;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
}
.Number-Decrement {
    left: 0;
}
.Number-Increment {
    right: 0;
}
.Number-Decrement_active,
.Number-Increment_active {
    pointer-events: auto;
    touch-action: auto;
}
.Number_disabled .Number-Decrement_active,
.Number_disabled .Number-Increment_active {
    user-select: none;
    pointer-events: none;
    touch-action: none;
}
.Number-Icon {
    width: 12px;
    height: 12px;
    fill: var(--color-gray);
}
.Number-Icon_active {
    fill: var(--color-black);
}
.Number_disabled .Number-Icon_active {
    fill: var(--color-gray);
}