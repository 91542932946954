.Projects {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.Projects-Filter {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 20%;
    max-width: 20%;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
}
.Projects-Content {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 80%;
    max-width: 80%;
    padding-left: 15px;
}
.Projects-FilterTitle {
    font-size: var(--font-s-middle);
}
.Projects-Option {
    flex-direction: column;
}
.Projects-Items {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px;
    row-gap: 15px;
}
.Projects-Item {
    width: 30%;
    border: 2px solid var(--color-light-gray);
    border-radius: 12px;
    padding: 15px;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    margin: auto;
    height: 100%;
}
.Projects-ItemImages {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    overflow: hidden;
}
.Projects-ItemImage {
    width: 100%;
    height: auto;
}
.Projects-ItemName {
    font-weight: bold;
    font-size: var(--font-s-middle);
}
.Projects-ItemDate,
.Projects-ItemUser {
    font-size: var(--font-smaller);
}
.Projects-ItemPrice {
    display: flex;
    column-gap: 10px;
    font-size: var(--font-middle);
}
.Projects-ItemValue {
    font-weight: bold;
}
.Projects-Button {
    padding: 8px;
}