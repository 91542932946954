.ObjectSelect {
    display: flex;
    align-items: center;
    border-radius: 8px;
    padding: 10px;
    border: none;
}
.ObjectSelect-Error {
    font-size: 14px;
    font-weight: 400;
    color: var(--color-black);
}
.ObjectSelect-WrapperSearch {
    width: 100%;
    position: fixed;
    max-width:356px;
    z-index: 99;
    background-color: var(--color-white);
    margin-top: -20px;
    padding: 20px 0 15px 0;
}
.ObjectSelect-SearchContent {
    width:100%;
}
.ObjectSelect-Content_search {
    padding-top: 45px;
}
.ObjectSelect-SearchUI {
    width: 100%;
    height: 100%;
    padding: 5px;
}
.ObjectSelect-ButtonClearSearch {
    position: absolute;
    top: 50%;
    right: 11px;
    width: 20px;
    height: 20px;
    margin-top: -8px;
    z-index: 99;
}