.WizardOrderForm {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0 -5px;
}
.WizardOrderForm-Field,
.WizardOrderForm-FiledGroup {
    box-sizing: border-box;
    flex-direction: column;
    flex-grow: 0;
    /*flex-shrink: 0;*/
    flex-basis: 50%;
    max-width: 50%;
    margin-bottom: 15px;
    display: flex;
    padding: 0 5px;
}
.WizardOrderForm-Field_full {
    flex-basis: 100%;
    max-width: 100%;
}
.WizardOrderForm-Field_type_checkbox {
    flex-basis: 70%;
    max-width: 70%;
}
.WizardOrderForm-Field_type_buttons {
    flex-basis: 30%;
    max-width: 30%;
}
@media screen  and (max-width: 767px) {
    .WizardOrderForm-Field,
    .WizardOrderForm-Field_type_checkbox,
    .WizardOrderForm-Field_type_buttons {
        flex-basis: 100%;
        max-width: 100%;
    }
}
.WizardOrderForm-FieldHeader {
    display: flex;
    margin-bottom: 15px;
    align-items: center;
}
.WizardOrderForm-FieldIcon {
    width: 20px;
    height: 20px;
}
.WizardOrderForm-FieldTitle {
    font-size: var(--font-big);
    font-weight: bold;
    margin-left: 15px;
}
.WizardOrderForm-Button {
    margin-left: auto;
    padding: 8px;
}
.WizardOrderForm-Error {
    color: var(--color-red);
    padding: 5px;
}
.WizardOrderForm-Input_error {
    border: 2px solid var(--color-red);
}
.WizardOrderForm-Checkbox_error .Checkbox .Checkbox-Label{
    color: var(--color-red);
}
.WizardOrderForm-Checkbox_error .Checkbox .Checkbox-Box:before {
    border: 2px solid var(--color-red);
}