.WizardSpec {
    position: relative;
    border-radius: 8px;
    background-color: var(--color-white);
    padding: 15px;
}
.WizardSpec-Title {
    font-weight: bold;
    font-size: var(--font-big);
    margin-bottom: 15px;
}
.WizardSpec-Images {
    display: flex;
    width: 100%;
    justify-content: space-between;
}
.WizardSpec-Snapshots,
.WizardSpec-Cutting {
    display: flex;
    width: 50%;
    flex-direction: column;
}
.WizardSpec-Snapshot {
    position: relative;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    border: 1px solid var(--color-gray);
    border-radius: 8px;
    padding: 8px;
    margin-bottom: 8px;
}
.WizardSpec-SnapshotImage {
    position: relative;
    width: 48%;
    height: auto;
}
.WizardSpec-SnapshotImage_type_visual {
    margin-right: 4%;
}
.WizardSpec-Image {
    width: 100%;
}
.WizardSpec-ShowAspect {
    margin-left: auto;
}
.WizardSpec-Button {
    padding: 8px;
}
.WizardSpec-AddSnapshot {
    display: inline-block;
    margin-top: 15px;
}
.WizardSpec-Cutting {
    flex-direction: row;
    margin-left: 16px;
}
.WizardSpec-TabletopCutting,
.WizardSpec-ApronCutting {
    border: 1px solid #ccd7e7;
    border-radius: 8px;
    padding: 8px;
}
.WizardSpec-TabletopCutting {
    margin-right: 4%;
}
.WizardSpec-ApronCutting {

}
.WizardSpec-ImageContainer {
    position: relative;
}
.WizardSpec-CuttingItem {
    margin-bottom: 10px;
    display: flex;
    flex-wrap: wrap;
    gap: 1px 5px;
}
.WizardSpec-CuttingIndex {
    font-weight: bold;
}
.WizardSpec-GroupTitle {
    font-weight: bold;
    font-size: var(--font-middle);
    margin-bottom: 15px;
    margin-top: 20px;
}
.WizardSpec-GroupContent {
    width: 100%;
    display: block;
    overflow: hidden;
    border-radius: 8px;
    background-color: var(--color-light-gray);
    border-spacing: 5px;
}
.WizardSpec-GroupHeader {
    min-height: 30px;
    box-sizing: border-box;
    margin-bottom: 10px;
}
.WizardSpec-GroupCell {
    width: 10%;
    padding: 5px;
}
.WizardSpec-GroupCell_size_s {
    width: 5%;
}
.WizardSpec-GroupCell_size_sm {
    width: 7%;
}
.WizardSpec-GroupCell_size_l {
    width: 35%;
}
.WizardSpec-GroupRow {
    margin-bottom: 10px;
    box-sizing: border-box;
    border-radius: 8px;
    background-color: var(--color-white);
}
.WizardSpec-GroupRow_subIndex .WizardSpec-GroupCell_index {
    padding-left: 15px;
}