.Header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    color: var(--color-white);
    background: none;
    height: 80px;
    padding: 15px;
    display: flex;
    justify-content: space-between;
    z-index: 1000;
}
.Header_size_small {
    height: 56px;
    padding: 8px 15px;
}
.Header-LogoMenu {
    display: flex;
    background-color: var(--color-light-gray);
    border: 1px solid var(--color-gray);
    border-radius: 8px;
}
.Header-Logo {
    height: 100%;
    width: 50px;
    margin: 0;
    background: none;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
}
.Header-PhoneContainer {
    margin-left: 20px;
    font-size: 15px;
}
.Header-Place {
    background-color: var(--color-light-gray);
    border-radius: 8px;
}
.Header-Place_border {
    background-color: var(--color-light-gray);
    border: 1px solid var(--color-gray);
    border-radius: 8px;
}
.Header-FlexContainer {
    display: flex;
    justify-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 10px;
}
.Header-WaContainer {
    margin-left: 20px;
    margin-right: auto;
}
.Header-TitleContainer {
    margin-left: 20px;
    margin-right: 20px;
    width: 100%;
}
.Header-Title {
    vertical-align: middle;
    height: 24px;
    margin-right: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    color: var(--color-black);
    font-size: 15px;
    align-items: center;
}
.Header-CityContainer {
    margin-left: auto;
    margin-right: 20px;
}
@media screen  and (max-width: 767px) {
    .Header-Logo {
        width: 40px;
    }
    .MenuBurger.Header-MenuBurger {
        width: 40px;
    }
    .Header-PhoneContainer {
        margin-left: 10px;
    }
    .Header-WaContainer {
        margin-left: 10px;
        margin-right: 10px;
    }
    .Header-CityContainer {
        margin-right: 10px;
    }
}