.WizardEdit {
    width: 100%;
    height: 100%;
}
.WizardEdit-Editor {
    width: 55vw;
    height: 55vh;
    position: absolute;
    top: 0;
    left: 0;
}
.WizardEdit-CuttingCanvas {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}
.WizardEdit-LoadingContainer {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.WizardEdit-Loading {
    font-size: var(--font-middle);
}
.WizardEdit-Loading_error {
    color: var(--color-red);
}
.WizardEdit .ModulesCatalogFilter{
    display: none;
}