
.InputFile{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    border-radius: 8px;
    border: 1px solid #ccc; 
    background-color: inherit;
    width: 100%;
    padding: 4px 16px;
    cursor: pointer;
    margin-bottom: 16px;
}

.InputFile-File{
    display: flex;
    justify-content: space-between;
    gap:8px;
    align-items: center;
    width: fit-content;
    width: 100%;
    padding: 6px 12px;
    border: 1px solid #ccc; 
    border-radius: 8px;
    overflow-x: hidden;
    margin-bottom: 16px;
}
.InputFile-File span{
    color: rgb(33, 37, 41);
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5; 
    overflow-x: hidden;
}

.InputFile-Hidden{
    display: none;
}
