.WizardCurrentStep {
    position: absolute;
    top: 115px;
    left: 15px;
    bottom: 10px;
    width: 400px;
    border-radius: 8px;
    background-color: var(--color-light-gray);
    z-index: 2;
}
.WizardCurrentStep-Container {
    width: 100%;
    height: 100%;
    padding: 20px 10px 63px 20px;
    position: relative;
}
.WizardCurrentStep-Content {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    padding-right: 10px;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
}
.WizardCurrentStep-Buttons {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 0 20px 10px 20px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.WizardCurrentStep-Button {
    padding: 10px;
    border: 1px solid var(--color-gray);
    border-radius: 8px;
}
@media screen  and (max-width: 1680px) {
    .WizardCurrentStep {
        width: 360px;
    }
}

@media screen  and (max-width: 1280px) {
    .WizardCurrentStep {
        bottom: 60px;
    }
}
.WizardCurrentStep-StepItem {
    border-radius: 8px;
    background-color: var(--color-white);
    padding: 10px;
    margin-bottom: 20px;
}
.WizardCurrentStep-StepHeader {
    display: flex;
    font-size: var(--font-middle);
    font-weight: bold;
    padding: 10px 0;
    cursor: pointer;
}
.WizardCurrentStep-StepItem_active .WizardCurrentStep-StepHeader {
    border-bottom: 1px solid var(--color-light-gray);
    margin-bottom: 10px;
}
.WizardCurrentStep-StepContent {
    margin-bottom: 10px;
    display: none;
}
.WizardCurrentStep-StepItem_active .WizardCurrentStep-StepContent {
    display: block;
}
.WizardCurrentStep-StepButtons {
    justify-content: space-between;
    align-items: center;
    display: none;
}
.WizardCurrentStep-StepItem_active .WizardCurrentStep-StepButtons {
    display: flex;
}
.WizardCurrentStep-StepArrow {
    margin-left: auto;
}
.WizardCurrentStep-StepItem_active .WizardCurrentStep-StepArrow {
    transform: rotate(225deg);
}
.WizardCurrentStep_step_services,
.WizardCurrentStep_step_spec,
.WizardCurrentStep_step_buy {
    right: 15px;
    width: auto;
    z-index: 100;
    bottom: 60px;
}
@media screen  and (max-width: 767px) {
    .WizardCurrentStep_step_buy {
        bottom: 10px;
    }
    .WizardCurrentStep-Buttons {
        display: none;
    }
    .WizardCurrentStep-Container {
        padding-bottom: 20px;
    }
}
.WizardCurrentStep_step_modules .WizardCurrentStep-Content {
    height: 100%;
    overflow: hidden;
}
.WizardCurrentStep_hidden .WizardCurrentStep-Content {
    overflow: inherit;
}