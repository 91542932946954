.GalleryCarousel {

}
.GalleryCarousel  ._3q7r8 {
    background-color: var(--color-white);
}
.GalleryCarousel  ._3lLfB,
.GalleryCarousel  ._3LX_O {
    filter: none;
}
.GalleryCarousel  ._1BRif::before,
.GalleryCarousel  ._t1897::before {
    background-color: var(--color-white);
}