.ExportGroupModules-Button {
  padding: 10px;
  border: 1px solid var(--color-gray);
  border-radius: 8px;
  margin-top: 20px;
}
.ExportGroupModules-Content {
  padding-right: 16px;
  margin-top: 16px;
}

.ExportGroupModules-Header {
  display: flex;
  padding-bottom: 10px;
  margin: 16px 0 0 0px;
  border-bottom: 2px solid var(--color-light-gray);
  align-items: center;
}
.ExportGroupModules-Title {
  display: flex;
  padding-bottom: 10px;
  margin: 0px;
  align-items: center;
  margin-top: 16px;
}
.ExportGroupModules-Select {
  width: 100%;
  margin-bottom: 16px;
  display: block;
}
.ExportGroupModules-Success-Message{
    color: var(--color-green);
}
