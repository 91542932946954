.City {
    display: flex;
    align-items: center;
    position: relative;
}
.City_hover {
    cursor: pointer;
}
.City-Icon {
    fill: var(--color-black);
}
.City-Name {
    font-size: 16px;
    color: var(--color-light-black);
    white-space: nowrap;
    border-bottom: 1px dashed;
}
.City-Name_hover {
    color: var(--color-black);
}
.City-Wrapper {
    width: 320px;
    display: flex;
    flex-direction: column;
}
.City-Wrapper_blur {
    filter: blur(3px);
}
.City-Title {
    font-weight: 700;
    color: var(--color-black);
    font-size: 19px;
    text-transform: none;
    text-align: left;
    display: block;
    border-bottom: 2px solid var(--color-light-gray);
    padding-bottom: 20px;
    margin-bottom: 20px;
}
.City-Current {
    color: var(--color-black);
    margin-bottom: 20px;
    font-size: 17px;
    display: flex;
}
.City-CurrentName {
    margin-left: 8px;
    font-weight: 700;
}
.City-Input {
    margin-bottom: 15px;
}
.City-Scroller {
    height: 300px;
    overflow: hidden;
    overflow-y: auto;
}
.City-Item {
    font-size: 13px;
    font-weight: 400;
    color: var(--color-light-black);
    padding: 5px;
}
.City-Item_hover {
    cursor: pointer;
}
.City-Item_active {
    color: var(--color-red);
    font-weight: 700;
}