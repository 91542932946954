.RoomParamsDecor {
    border-radius: 8px;
    border: 1px solid var(--color-gray);
    width: 48%;
    margin: 2px auto;
    padding: 10px;
    background-color: var(--color-light-gray);
    display: flex;
    flex-direction: column;
}
.RoomParamsDecor-Item {
    width:48%;
    margin: 10px;
}
.RoomParamsDecor-Name {
    margin: auto;
    font-size: var(--font-s-middle);
    margin-bottom: 10px;
}
.RoomParamsDecor-Image {
    width: 100%;
    max-height: 150px;
    border-radius: 8px;
    margin-bottom: 10px;
}
.RoomParamsDecor-Button {
    padding: 8px;
}