.ObjectSelectItem {
    min-height: 250px;
    padding: 13px 11px;
    border-radius: 12px;
    border: 1px solid var(--color-light-gray);
    background-color: var(--color-white);
    overflow: hidden;
    box-shadow: none;
}
.ObjectSelectItem_animated {
    transition: .4s;
}
.ObjectSelectItem_hover {
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
}
.ObjectSelectItem_active {
    border: 2px solid var(--color-light-green);
}
.ObjectSelectItem-Wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    height: 100%;
}
.ObjectSelectItem-Name {
    font-size: var(--font-s-middle);
    color: var(--color-black);
}
.ObjectSelectItem-Images {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    border-radius: 12px;
    border: 1px solid var(--color-gray);
    height: 100%;
}
.ObjectSelectItem-Color,
.ObjectSelectItem-Icon {
    height: 100%;
    object-fit: cover;
    border: 1px solid var(--color-gray);
}
.ObjectSelectItem-Image {
    width: 100%;
    object-fit: cover;
}
.ObjectSelectItem-Color {
    width: 100%;
}
.ObjectSelectItem-Icon {
    fill: var(--color-black);
}
.ObjectSelectItem-Buttons {
    display: flex;
}
.ObjectSelectItem-Button {
    padding: 8px;
    margin-left: auto;
}
.ObjectSelectItem-Note {
    font-size: var(--font-smaller);
}