.Accordion {
    width: 100%;
    overflow: hidden;
    border-radius: 8px;
    background-color: var(--color-white);
}
.Accordion_animated {
    transition: .4s;
}
.Accordion-Header {
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
}
.Accordion-Header_cursor_pointer {
    cursor: pointer;
}
.Accordion-Text {
    font-size: var(--font-middle);
    color: var(--color-black);
}
.Accordion-Icon {
    width: 16px;
    height: 9px;
    fill: var(--color-black);
}
.Accordion-Icon_animated {
    transition: .4s;
}
.Accordion-Icon_transform {
    transform: rotateZ(-180deg);
}
.Accordion-Wrapper {
    width: 100%;
    height: auto;
    padding: 0 20px 20px 20px;
}