.Select {
    display: inline-block;
}
.Select .Select2 {
    width: 100%;
    position: static;
}
.Select .Select2 .Select2-Button:active:before {
    background-color: var(--color-white);
}
.Select .Button2 {
    color: var(--color-black);
    height: 34px;
    border-radius: 8px;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    padding: 0 8px;
}
.Select.Select_placeholder .Button2 {
    color: var(--color-black);
}
.Select .Button2:before {
    border-radius: 8px;
    border: 1px solid var(--color-gray);
}
.Select .Select2 .Button2:hover:before {
    border-color: var(--color-gray);
}
.Select .Button2 .Icon {
    position: static;
    width: 8px;
    height: 5px;
    margin-left: 5px;
}
.Select .Button2 .Icon.Icon_direction_top {
    transform: rotate(180deg);
}
.Select .Button2 .Button2-Text {
    font-weight: 400;
    color: var(--color-black);
}
.Select2 .Popup2 {
    border: none;
}
.Select2 .Popup2:before {
    border-radius: 8px;
}
.Select .Menu {
    padding-top: 8px;
    padding-bottom: 8px;
    border-radius: 8px;
    background-color: var(--color-white);
}
.Select .Menu .Menu-Item {
    display: flex;
    align-items: center;
    padding-left: 17px;
    height: 26px;
    font-weight: 400;
    color: var(--color-black);
}
.Select .Menu .Menu-Item.Menu-Item_checked {
    background-image: none;
    background-color: var(--color-white);
    color: var(--color-light-red);
}
.Select .Menu .Menu-Item:hover,
.Select .Menu .Menu-Item.Menu-Item_hovered {
    background-color: var(--color-white);
    color: var(--color-light-red);
}