.CreateForm {
  position: absolute;
  top: 60px;
  right: 15px;
  bottom: 10px;
  width: calc(50% - 20px);
  border-radius: 8px;
  background-color: var(--color-light-gray);
  padding: 20px 10px 20px 20px;
  z-index: 2;
}

.CreateForm-Content .text-danger, .CreateForm-Error{
  color: rgba(246, 62, 62, 0.8);
  margin-bottom: 16px;
}

.CreateForm-Button {
  padding: 10px;
  margin-bottom: 16px;
}

.CreateForm-Container {
    width: 100%;
    height: 100%;
    overflow-y: auto;
}

.CreateForm-Box-Input {
    display: flex;
    gap: 16px;
    max-width: 800px;
}

.CreateForm .Select, .CreateForm-Select {
    width: 100%;
    margin-bottom: 16px;
    display: block;
}

.CreateForm-Checkbox{
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  gap: 16px;
  flex-wrap: wrap;
}

.CreateForm-Tumbler{
  margin-bottom: 16px;
}

.CreateForm-Textinput{
  margin-bottom: 16px;
}

.CreateForm-Textinput input[type="number"] {
  -webkit-appearance: none;
  appearance: textfield;
  -moz-appearance: textfield;
}

.CreateForm-Textinput input[type="number"]::-webkit-inner-spin-button,
.CreateForm-Textinput input[type="number"]::-webkit-outer-spin-button {
  display: none;
  -webkit-appearance: none;
  margin: 0;
}

.CreateForm-Modal-Content {
  padding: 20px;
  border: solid 1px #bcbcbc;
  border-radius: 8px;
  width: 100%;
  margin-bottom: 16px;
}

.CreateForm-Content{
  padding-right: 16px;
  margin-top: 16px;
}

.CreateForm-Header {
  display: flex;
  padding-bottom: 10px;
  margin: 16px 0 0 0px;
  border-bottom: 2px solid var(--color-light-gray);
  align-items: center;
}

.CreateForm-Title {
  display: flex;
  padding-bottom: 10px;
  margin: 0px;
  align-items: center;
  margin-top: 16px;
}

.CreateForm-Children{
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #bcbcbc;
  border-radius: 8px;
  padding: 6px 16px;
  margin-bottom: 16px;
}

.CreateForm-Btn{
  border: none;
  background-color: transparent;
  cursor: pointer;
  padding-top: 3px;
}

.CreateForm-Box-Btn{
  display: flex;
  gap: 16px;
  justify-content: center;
  align-items: center;
}

.CreateForm-Btn-Icon{
  position: relative;
}

.CreateForm-Button-Hidden{
  display: none;
}

.CreateForm-Container-Btn{
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  justify-content: flex-start;
  align-items: center;
}
