.PageIndex {
    height: 100%;
}
.PageIndex-Main {
    padding-top: 85px;
    background-image: url('../../assets/images/main-fon.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100%;
    width: 100%;
    background-position: center center;
    background-color: var(--color-white);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    color: var(--color-white);
}
.PageIndex-Item {
    background-color: var(--color-white);
    padding: 20px;
    margin: 20px;
    border-radius: 8px;
    box-shadow: 0 0 5px var(--color-gray);
    width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 460px;
    position: relative;
}
.PageIndex-Item:after {
    content: ' ';
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-top: 20px solid;
    position: absolute;
    left: 50%;
    margin-left: -20px;
    bottom: -20px;
}
.PageIndex-Item_color_green {
    background-color: var(--color-green);
}
.PageIndex-Item_color_red {
    background-color: var(--color-red);
}
.PageIndex-Item_color_violet {
    background-color: var(--color-violet);
}
.PageIndex-Item_color_green:after {
    border-top-color: var(--color-green);
}
.PageIndex-Item_color_red:after {
    border-top-color: var(--color-red);
}
.PageIndex-Item_color_violet:after {
    border-top-color: var(--color-violet);
}
.PageIndex-Wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.PageIndex-ItemTitle {
    font-size: 21px;
    margin-top: 10px;
    margin-bottom: 20px;
    border-left: 3px solid var(--color-white);
    text-transform: uppercase;
    padding: 0 0 0 15px;
    font-weight: 700;
    width: 100%;
    color: var(--color-white);
}
.PageIndex-ItemDescription {
    color: var(--color-white);
    font-size: 16px;
    font-weight: 400;
}