
.WizardEditCurrent {
    position: absolute;
    top: 60px;
    left: 15px;
    bottom: 10px;
    width: 400px;
    border-radius: 8px;
    background-color: var(--color-light-gray);
    z-index: 2;
}

.WizardEditCurrent-Buttons {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 0 20px 10px 20px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.WizardEditCurrent-Button {
    padding: 10px;
    border: 1px solid var(--color-gray);
    border-radius: 8px;
}
/* @media screen  and (max-width: 1680px) {
    .WizardEditCurrent {
        width: 360px;
    }
} */

.WizardEditCurrent-StepItem {
    border-radius: 8px;
    background-color: var(--color-white);
    padding: 10px;
    margin-bottom: 20px;
}
.WizardEditCurrent-StepHeader {
    display: flex;
    font-size: var(--font-middle);
    font-weight: bold;
    padding: 10px 0;
    cursor: pointer;
}
.WizardEditCurrent-StepItem_active .WizardEditCurrent-StepHeader {
    border-bottom: 1px solid var(--color-light-gray);
    margin-bottom: 10px;
}
.WizardEditCurrent-StepContent {
    margin-bottom: 10px;
    display: none;
}
.WizardEditCurrent-StepItem_active .WizardEditCurrent-StepContent {
    display: block;
}
.WizardEditCurrent-StepButtons {
    justify-content: space-between;
    align-items: center;
    display: none;
}
.WizardEditCurrent-StepItem_active .WizardEditCurrent-StepButtons {
    display: flex;
}
.WizardEditCurrent-StepArrow {
    margin-left: auto;
}
.WizardEditCurrent-StepItem_active .WizardEditCurrent-StepArrow {
    transform: rotate(225deg);
}
.WizardEditCurrent_step_services,
.WizardEditCurrent_step_spec,
.WizardEditCurrent_step_buy {
    right: 15px;
    width: auto;
    z-index: 100;
    bottom: 60px;
}
@media screen  and (max-width: 767px) {
    .WizardEditCurrent_step_buy {
        bottom: 10px;
    }
    .WizardEditCurrent-Buttons {
        display: none;
    }
    .WizardEditCurrent-Container {
        padding-bottom: 20px;
    }
}
.WizardEditCurrent_step_modules .WizardEditCurrent-Content {
    height: 100%;
    overflow: hidden;
}
.WizardEditCurrent_hidden .WizardEditCurrent-Content {
    overflow: inherit;
}