.CreateObjectOptions-Body {
    width: 500px;
}
.CreateObjectOptions-Options {
    display: flex;
    gap: 10px;
    flex-direction: column;
}
.CreateObjectOptions-Header {
    display: flex;
    padding-bottom: 10px;
    margin: 10px;
    border-bottom: 2px solid var(--color-light-gray);
    align-items: center;
}
.CreateObjectOptions-Images {
    display: flex;
    height: 60px;
    max-height: 110px;
    max-width: 130px;
    margin: auto;
}
.CreateObjectOptions-Icon {
    height: 100%;
    width: auto;
}
.CreateObjectOptions-NameContainer {
    width: 75%;
}
.CreateObjectOptions-Name {
    font-size: var(--font-middle);
}
.CreateObjectOptions-Footer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}
.CreateObjectOptions-Button {
    padding: 10px;
}
.CreateObjectOptions-SizesItem {
    display: flex;
}
.CreateObjectOptions-SizesTitle {
    color: var(--color-light-black);
}
.CreateObjectOptions-SizesValue {
    font-weight: bold;
    margin-left: 8px;
}
.CreateObjectOptions-Notes {
    font-weight: normal;
    color: var(--color-light-black);
    font-size: var(--font-small);
    text-align: center;
    margin-top: 10px;
}
