.ModulesCatalog {
    position: relative;
    overflow: hidden;
    height: 100%;
}
.ModulesCatalog-Header {
    margin-bottom: 15px;
    position: absolute;
    width: 100%;
}
.ModulesCatalog-Title {
    font-size: var(--font-middle);
    margin-bottom: 15px;
}
.ModulesCatalog-SearchContainer {
    display: flex;
}
.ModulesCatalog-Back {
    background-color: var(--color-white);
    padding: 5px;
    border-radius: 8px;
    width: 25%;
    opacity: 0.2;
}
.ModulesCatalog-Back.Button_active {
    color: var(--color-red);
    opacity: 1;
}
.ModulesCatalog-Back.Button_active .Svg {
    fill: var(--color-red);
}
.ModulesCatalog-Search {
    position: relative;
    padding-left: 5px;
    width: 72%;
}
.ModulesCatalog-SearchButton {
    width: 20px;
    height: 20px;
    position: absolute;
    right: 5px;
    top: 0;
    padding: 0;
    transform: translateY(50%);
    z-index: 3;
    border-radius: 50%;
    background: var(--color-white);
    cursor: pointer;
}
.ModulesCatalog-ContentContainer {
    height: 100%;
    padding-top: 85px;
    overflow: hidden;
}
.ModulesCatalog-ContentContainer_filter {
    padding-top: 115px;
}
.ModulesCatalog-Content {
    height: 100%;
    overflow-y: scroll;
}
