.Tip {
    width: 20px;
    height: 20px;
    position: relative;
}
.Tip-Svg {
    width: 20px;
    height: 20px;
}
.Tip-Message {
    z-index: 1000;
    width: 280px;
    padding: 10px;
    background-color: var(--color-white);
    border: 2px solid var(--color-light-gray);
    border-radius: 8px;
}