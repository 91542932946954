.EditMaterialsList-Item {
    /* margin-bottom: 20px; */
    max-height: 400px;
    min-height: 350px;
}
.EditMaterialsList-Header {
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

.EditMaterialsList-Button {
    padding: 10px;
    border: 1px solid var(--color-gray);
    border-radius: 8px;
    margin-right: 20px;
}
.EditMaterialsList-Container {
    width: 100%;
    height: 100%;
    position: relative;
}
.EditMaterialsList-Content {
    padding: 20px 40px 20px 40px;
    display: grid;
    grid-template-columns: repeat(4, calc((100% - 40px* 2) / 4));
    gap: 20px;
    height: 100%;
}
@media screen  and (max-width: 1400px) {
    .EditMaterialsList-Content {
        grid-template-columns: repeat(3, calc((100% - 40px* 2) / 3));
    }
}

@media screen  and (max-width: 1024px) {
    .EditMaterialsList-Content {
        grid-template-columns: 50% 50%;
    }
}
@media screen  and (max-width: 768px) {
    .EditMaterialsList-Content {
        grid-template-columns: 100%;
    }
}

.EditMaterialsList-Footer {
    width: 100%;
    text-align: end;
}