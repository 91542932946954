.ReplaceMenu {
    height: 100%;
}
.ReplaceMenu-Title {
    font-size: var(--font-middle);
    margin-bottom: 15px;
}
.ReplaceMenu-Body {
    height: 100%;
    overflow-y: scroll;
}
.ReplaceMenu-Items {
    display: flex;
    flex-wrap: wrap;
}
.ReplaceMenu-Item {
    width: 30%;
    margin: 10px auto;
}