@font-face {
    font-family: 'Exo2Condensed';
    src: url('../../assets/fonts/Exo2Condensed/Regular/Exo2Condensed-Regular.eot');
    src: url('../../assets/fonts/Exo2Condensed/Regular/Exo2Condensed-Regular.eot?#iefix') format('embedded-opentype'),
    url('../../assets/fonts/Exo2Condensed/Regular/Exo2Condensed-Regular.woff2') format('woff2'),
    url('../../assets/fonts/Exo2Condensed/Regular/Exo2Condensed-Regular.woff') format('woff'),
    url('../../assets/fonts/Exo2Condensed/Regular/Exo2Condensed-Regular.ttf') format('truetype'),
    url('../../assets/fonts/Exo2Condensed/Regular/Exo2Condensed-Regular.svg#Exo2Condensed-Regular') format('svg');
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: 'Exo2Condensed';
    src: url('../../assets/fonts/Exo2Condensed/Bold/Exo2Condensed-Bold.eot');
    src: url('../../assets/fonts/Exo2Condensed/Bold/Exo2Condensed-Bold.eot?#iefix') format('embedded-opentype'),
    url('../../assets/fonts/Exo2Condensed/Bold/Exo2Condensed-Bold.woff2') format('woff2'),
    url('../../assets/fonts/Exo2Condensed/Bold/Exo2Condensed-Bold.woff') format('woff'),
    url('../../assets/fonts/Exo2Condensed/Bold/Exo2Condensed-Bold.ttf') format('truetype'),
    url('../../assets/fonts/Exo2Condensed/Bold/Exo2Condensed-Bold.svg#Exo2Condensed-Bold') format('svg');
    font-weight: 700;
    font-style: normal;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Exo2Condensed', sans-serif;
}
html {
    overflow-x: hidden;
    margin-right: calc(-1 * (100vw - 100%));
}
::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    border-radius: 8px;
}
::-webkit-scrollbar-track {
    background: var(--color-light-gray);
}
::-webkit-scrollbar-thumb {
    background: var(--color-gray);
    border-radius: 8px;
}
::-webkit-scrollbar-thumb:hover {
    background: var(--color-light-black);
}
::placeholder {
    color: var(--color-light-black);
}
.App {
    width: 100vw;
    height: 100vh;
}
.App-Error {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: var(--font-middle);
    color: var(--color-red);
}
.Page {
    width: 100%;
    height: 100%;
}
.Page_blur {
    filter: blur(3px);
}
@keyframes shadowIcon {
    0% {
        box-shadow: 0 0 0 0 rgba(108, 151, 99, .9);
    }
    100% {
        box-shadow: 0 0 0 4px rgba(108, 151, 99, 0);
    }
}