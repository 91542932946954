.KitchenParams-Item {
    margin-bottom: 10px;
}
.KitchenParams-ItemTitle {
    color: var(--color-light-black);
    margin-bottom: 10px;
    display: flex;
    font-size: var(--font-s-middle);
}
.KitchenParams-TitleTip {
    margin-left: auto;
    cursor: pointer;
}
.KitchenParams-TipSvg {
    width: 20px;
    height: 20px;
}
.KitchenParams-Accordion {
    margin-bottom: 15px;
}