.WizardModules {
    position: relative;
    overflow: hidden;
    height: 100%;
}
.WizardModules-Container {
    width: 100%;
    height: 100%;
    padding: 20px 10px 63px 20px;
    position: relative;
}
.WizardModules-Content {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    padding-right: 10px;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
}
.WizardModules-Buttons {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 0 20px 10px 20px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.WizardModules-Button {
    padding: 10px;
    border: 1px solid var(--color-gray);
    border-radius: 8px;
}
