.ProjectItem {
    border-radius: 8px;
    border: 2px solid var(--color-light-gray);
    padding: 20px;
    overflow: hidden;
    background-color: var(--color-white);
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
}
.ProjectItem-Images {
    height: 216px;
    text-align: center;
    background-color: var(--color-light-gray);
    display: flex;
    justify-content: center;
    width: 100%;
    border: 2px solid var(--color-gray);
    border-radius: 5px;
    align-items: center;
    padding: 5px;
}
.ProjectItem-Image {
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.ProjectItem-Content {
    padding: 5px;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}
.ProjectItem-Title {
    font-size: 17px;
    font-weight: 700;
    margin-bottom: 8px;
}
.ProjectItem-Article {
    margin-bottom: 8px;
}
.ProjectItem-Price {
    font-size: 17px;
    font-weight: 400;
    color: var(--color-light-black);
    margin-bottom: 16px;
}
.ProjectItem-Description {
    font-size: 16px;
    font-weight: 400;
}
.ProjectItem-Button {
    font-size: 14px;
    font-weight: 400;
    border-width: 1px;
    border-style: solid;
    border-radius: 8px;
    padding: 10px;
}
.ProjectItem-PriceContainer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}
.ProjectItem-Price {
    margin-right: auto;
}