.FormFacade {
  position: absolute;
  top: 60px;
  right: 15px;
  bottom: 10px;
  width: calc(50% - 20px);
  border-radius: 8px;
  background-color: var(--color-light-gray);
  padding: 20px 10px 20px 20px;
  z-index: 2;
}
.FormFacade-Id {
  font-size: large;
  margin-bottom: 16px;
}

.FormFacade-Title{
  margin-bottom: 16px;
}

.FormFacade-Container {
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

.FormFacade-Header {
  margin-bottom: 16px;
}

.FormFacade-Button {
    padding: 10px;
    border: 1px solid var(--color-gray);
    border-radius: 8px;
    margin-right: 20px;
    margin-bottom: 16px;
}

.FormFacade-Textinput {
    width: 100%;
    margin-top: 8px;
}

.FormFacade-Form-Group{
  display: flex;
  flex-direction: column;
}

.FormFacade-СolorInput {
    margin-bottom: 16px;
    margin-left: 32px;
    appearance: none;
    width: 50px;
    height: 50px;
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    border-radius: 8px;
    cursor: pointer;
}

.FormFacade-СolorInput::-webkit-color-swatch-wrapper {
  padding: 0;
}

.FormFacade-СolorInput::-webkit-color-swatch {
  border: none;
  border-radius: 8px;
  padding: 0;
}

.FormFacade .Select, .FormFacade-Select {
  width: 100%;
  margin-bottom: 16px;
  display: block;
}

.FormFacade-Tumbler{
  margin-bottom: 16px;
}

.FormFacade-Textinput{
  margin-bottom: 16px;
}

.FormFacade-Textarea{
  margin-bottom: 16px;
  margin-top: 8px;
}

.FormFacade-Textinput input[type="number"] {
  -webkit-appearance: none;
  appearance: textfield;
  -moz-appearance: textfield;
}

.FormFacade-Textinput input[type="number"]::-webkit-inner-spin-button,
.FormFacade-Textinput input[type="number"]::-webkit-outer-spin-button {
  display: none;
  -webkit-appearance: none;
  margin: 0;
}

.FormFacade .Modal-Content{
  max-width: 600px;
}

.FormFacade-Children{
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #bcbcbc;
  border-radius: 8px;
  padding: 6px 16px;
  margin-bottom: 16px;
}

.FormFacade-Error{
  color: rgba(246, 62, 62, 0.8);
  margin-bottom: 16px;
}

.FormFacade-Values{
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

.FormFacade-Footer {
  width: 100%;
  text-align: start;
}
.FormFacade-Box-Btn{
  display: flex;
  gap: 16px;
  justify-content: center;
  align-items: center;
}

.FormFacade-Content .text-danger, .CreateForm-Error{
  color: rgba(246, 62, 62, 0.8);
  margin-bottom: 16px;
}

.FormFacade-Modal-Content{
  padding: 20px;
  border: solid 1px #bcbcbc;
  border-radius: 8px;
  margin-bottom: 16px;
  width: 100%;
}
