.Wizard-ProjectPrice {
    position: absolute;
    padding: 0 15px;
    top: 56px;
    right: 15px;
    background-color: var(--color-light-gray);
    border: 1px solid var(--color-light-gray);
    height: 48px;
    border-radius: 8px;
    min-width: 150px;
    width: 150px;
    line-height: inherit;
    text-align: left;
    z-index: 200;
    font-weight: normal;
    font-size: var(--font-s-middle);
}
.WizardProjectPrice-Container {
    height: 100%;
    position: relative;
    opacity: 1;
}
.WizardProjectPrice-Info {
    display: block;
    text-align: right;
}
.WizardProjectPrice-Switcher {
    position: absolute;
    cursor: pointer;
    height: 40px;
    width: 100%;
    top: 0;
    right: -10px;
    border: none;
    text-align: right;
    padding-top: 15px;
}
.WizardProjectPrice-Icon {
    transition: all 0.2s linear;
    fill: var(--color-light-black);
}
.WizardProjectPrice-Icon.WizardProjectPrice-Icon_active {
    transform: rotate(180deg);
}
.WizardProjectPrice-More {
    width: 260px;
}
.Popup.WizardProjectPrice-More {
    font-size: var(--font-s-middle);
}
.WizardProjectPrice-PriceItem {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid var(--color-gray);
    padding: 0 5px 3px 5px;
}
.WizardProjectPrice-PriceItem.WizardProjectPrice-PriceItem_header {
    display: block;
    text-align: right;
    border: none;
}
.WizardProjectPrice-PriceTitle.WizardProjectPrice-PriceTitle_full {
    color: var(--color-light-black);
    margin-right: 5px;
    font-size: var(--font-middle);
}
.WizardProjectPrice-PriceValue.WizardProjectPrice-PriceValue_full,
.WizardProjectPrice-PriceValue.WizardProjectPrice-PriceValue_header{
    margin-right: 5px;
    font-size: var(--font-middle);
    font-weight: bold;
    color: var(--color-red);
}
.WizardProjectPrice-PriceTitle {
    color: var(--color-light-black);
    margin-right: 5px;
}
.WizardProjectPrice-PriceValue {
    color: var(--color-black);
    margin-right: 5px;
}
.WizardProjectPrice-Error,
.WizardProjectPrice-inOrder {
    display: none;
}