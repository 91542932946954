.TumblerUI .Tumbler_view_default .Tumbler-Button {
    background-color: var(--color-light-gray);
    border: 2px solid var(--color-gray);
}
.TumblerUI .Tumbler_view_default .Tumbler-Button:hover {
    background-color: var(--color-white);
}
.TumblerUI .Tumbler_view_default .Tumbler-Button::after {
    left: 5px;
    border: 2px solid var(--color-gray);
}
.TumblerUI .Tumbler_view_default.Tumbler_size_l .Tumbler-Button::after {
    top: 4px;
}
.TumblerUI .Tumbler_view_default .Tumbler-Button:hover::after {
    background-color: var(--color-light-green);
}
.TumblerUI .Tumbler_view_default.Tumbler_size_m .Tumbler-Button {
    width: 40px;
    height: 22px;
    border-radius: 8px;
}
.TumblerUI .Tumbler_view_default.Tumbler_size_m .Tumbler-Button::after {
    width: 12px;
    height: 10px;
    border-radius: 5px;
    box-shadow: none;
}
.TumblerUI .Tumbler_view_default.Tumbler_size_m .Tumbler-Button:active::after {
    width: 12px;
    height: 10px;
}
.TumblerUI .Tumbler_view_default.Tumbler_size_l .Tumbler-Button {
    width: 60px;
    height: 30px;
    border-radius: 8px;
}
.TumblerUI .Tumbler_view_default.Tumbler_size_l .Tumbler-Button::after {
    width: 18px;
    height: 14px;
    border-radius: 5px;
    box-shadow: none;
}
.TumblerUI .Tumbler_view_default.Tumbler_size_l .Tumbler-Button:active::after {
    width: 18px;
    height: 14px;
}
.Tumbler-Label {
    width: calc(396px - 36px - 60px);
}
.TumblerUI .Tumbler_view_default[class] .Tumbler-Button[aria-pressed='true']::after {
    margin-left: -4px;
    border: 2px solid var(--color-green);
}
.TumblerUI .Tumbler_view_default[class] .Tumbler-Button[aria-pressed='true'] {
    background-color: var(--color-light-green);
    border: 2px solid var(--color-green);
}
.TumblerUI .Tumbler_view_default[class] .Tumbler-Button[aria-pressed='true']:hover {
    background-color: var(--color-light-green);
    border: 2px solid var(--color-green);
}
.TumblerUI .Tumbler_view_default[class] .Tumbler-Button[aria-pressed='true']:hover::after {
    background-color: var(--color-white);
}