.LoginForm {
    width: 500px;
    position: relative;
    display: flex;
    flex-direction: column;
    row-gap: 16px;
}
.LoginForm_blur {
    filter: blur(3px);
}
.LoginForm-Header {
    font-size: 19px;
    font-weight: 700;
    color: var(--color-black);
}
.LoginForm-Title {
    margin-bottom: 8px;
    color: var(--color-black);
    font-size: 16px;
    font-weight: 500;
}
.LoginForm-Notes {
    margin-bottom: 4px;
    font-size: 12px;
    font-weight: 500;
    color: var(--color-black);
}
.LoginForm-Errors {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
}
.LoginForm-Error {
    font-size: 14px;
    font-weight: 500;
    color: var(--color-red);
}
.LoginForm-Button {
    align-self: center;
    font-size: 14px;
    font-weight: 400;
    border-width: 1px;
    border-style: solid;
    border-radius: 8px;
    padding: 10px;
    margin-top: 10px;
}
.LoginForm-SendSMS {
    font-size: 14px;
    font-weight: 400;
    align-self: flex-start;
    text-decoration: none;
    border-bottom: 1px dashed;
}