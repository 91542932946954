.Textinput {

}
.Textinput-Control {
    background-color: var(--color-white);
    border: 2px solid var(--color-gray);
    height: 36px;
    padding: 5px 10px;
    border-radius: 8px;
    width: 100%;
    min-width: 40px;
}