.DeliveryObjectSelectHome-Title {
    margin-bottom: 10px;
}
.DeliveryObjectSelectHome-Address {
    display: flex;
    column-gap: 10px;
    margin-bottom: 10px;
}
.DeliveryObjectSelectHome-City {
    margin-top: 8px;
}
.DeliveryObjectSelectHome-Street {
    width: 100%;
}
.DeliveryObjectSelectHome-FloorContainer {
    display: flex;
    flex-wrap: wrap;
    row-gap: 10px;
}
.DeliveryObjectSelectHome-Tumbler {
    width: 50%;
}
.DeliveryObjectSelectHome-Floor {
    display: flex;
    column-gap: 10px;
    width: 50%;
}
.DeliveryObjectSelectHome-FloorTitle {
    margin-top: 8px;
}
.DeliveryObjectSelectHome-FloorValue {
    width: 100%;
}
.DeliveryObjectSelectHome-Input_error {
    border: 2px solid var(--color-red);
}
.DeliveryObjectSelectHome-Error {
    color: var(--color-red);
    padding: 5px;
}