.SmsConfirm-Title {
    font-size: 19px;
    font-weight: bold;
    margin-bottom: 20px;
}
.SmsConfirm-InputContainer {
    display: flex;
    column-gap: 10px;
    margin-bottom: 20px;
}
.SmsConfirm-Button {
    padding: 8px;
}
.SmsConfirm-Send {
    color: var(--color-green);
    border-bottom: 1px dashed;
}
.SmsConfirm-Seconds {
    margin-left: 10px;
    font-weight: bold;
    color: var(--color-green);
}
