.WizardSteps {
    position: absolute;
    top: 56px;
    width: 100%;
    height: 48px;
    padding: 0 185px 0 15px;
}
.WizardSteps-Container {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    background-color: var(--color-white);
    padding: 0 0 0 10px;
}
.WizardSteps-Steps {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-size: 15px 6px;

}
.WizardSteps-Step {
    width: 14.2857%;
    position: relative;
    cursor: pointer;
}
@media screen  and (max-width: 767px) {
    .WizardSteps-Step {
        width: 100%;
    }
    .Wizard-Steps .WizardSteps-Container {
        padding: 0;
    }
}
.WizardSteps-Step:before {
    position: absolute;
    width: 50%;
    height: 6px;
    background-color: var(--color-light-gray);
    bottom: 7px;
    left: 0;
    content: '';
}
.WizardSteps-Step:after {
    position: absolute;
    width: 50%;
    height: 6px;
    background-color: var(--color-light-gray);
    bottom: 7px;
    right: 0;
    content: '';
}
.WizardSteps-Step.WizardSteps-Step_complete:before,
.WizardSteps-Step.WizardSteps-Step_complete:after,
.WizardSteps-Step.WizardSteps-Step_current:last-child:after,
.WizardSteps-Step.WizardSteps-Step_current:before {
    background-color: var(--color-light-red);
}
.WizardSteps-Step:first-child:before {
    border-bottom-left-radius: 8px;
    border-top-left-radius: 8px;
}
.WizardSteps-Step:last-child:after {
    border-bottom-right-radius: 8px;
    border-top-right-radius: 8px;
}

.WizardSteps-StepTitle {
    text-align: center;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.WizardSteps-StepCircle {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: var(--color-gray);
    margin: 3px auto;
    z-index: 1;
    position: relative;
}
.WizardSteps-Step_complete .WizardSteps-StepTitle,
.WizardSteps-Step_current .WizardSteps-StepTitle {
    color: var(--color-light-red);
}
.WizardSteps-Step_complete .WizardSteps-StepCircle,
.WizardSteps-Step_current .WizardSteps-StepCircle {
    background-color: var(--color-light-red);
}
.WizardSteps-Step.WizardSteps-Step_view_buy {
    border-radius: 8px;
    background-color: var(--color-white);
    color: var(--color-green);
    border: 3px solid var(--color-light-green);
}
.WizardSteps-Step_current.WizardSteps-Step_view_buy,
.WizardSteps-Step_complete.WizardSteps-Step_view_buy {
    border-radius: 8px;
    background-color: var(--color-light-green);
    color: var(--color-white);
}
.WizardSteps-Step.WizardSteps-Step_view_buy .WizardSteps-StepTitle {
    color: var(--color-green);
}
.WizardSteps-Step_current.WizardSteps-Step_view_buy .WizardSteps-StepTitle,
.WizardSteps-Step_complete.WizardSteps-Step_view_buy .WizardSteps-StepTitle {
    color: var(--color-white);
}
.WizardSteps-Step_view_buy .WizardSteps-StepCircle {
    color: var(--color-white);
    background: url("../../../../assets/svg-icons/shop-green.svg") center center no-repeat;
    background-size: 15px 15px;
    width: 18px;
    height: 18px;
}
.WizardSteps-Step.WizardSteps-Step_current.WizardSteps-Step_view_buy .WizardSteps-StepCircle,
.WizardSteps-Step.WizardSteps-Step_complete.WizardSteps-Step_view_buy .WizardSteps-StepCircle {
    background: url("../../../../assets/svg-icons/shop-white.svg") center center no-repeat;
    background-size: 15px 15px;
}
.WizardSteps-Step_view_spec:after {
    background: linear-gradient(to right, var(--color-gray) 70%, var(--color-light-green));
}
.WizardSteps-Step_complete.WizardSteps-Step_view_spec:after,
.WizardSteps-Step_current.WizardSteps-Step_view_spec:after {
    background: linear-gradient(to right, var(--color-red) 70%, var(--color-light-green));
}
.WizardSteps-Step_view_buy:before {
    content: '';
    display: none;
}
.WizardSteps-Step_view_buy:after {
    content: '';
    display: none;
}