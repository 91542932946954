.ObjectOption {
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.ObjectOption_type_radioButton {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.ObjectOption_type_checkbox,
.ObjectOption_type_range,
.ObjectOption_type_text,
.ObjectOption_type_number {
    display: flex;
    width: 100%;
    align-items: center;
    flex-direction: row;
}
.ObjectOption_type_facadeMaterial,
.ObjectOption_type_corpusMaterial {
    display: flex;
    gap: 10px;
}
.ObjectOption-Title.ObjectOption_type_checkbox,
.ObjectOption-Title.ObjectOption_type_range {
    width: 50%;
}
.ObjectOption-Value.ObjectOption_type_checkbox,
.ObjectOption-Value.ObjectOption_type_range {
    width: 50%;
}
.ObjectOption-Title {
    font-size: var(--font-standard);
    color: var(--color-light-black);
}
.ObjectOption-Value {
    width: 100%;
}
.ObjectOption-WrapperTitle {
    display: flex;
}
.ObjectOption-TitleNameColor {
    margin-left: 10px;
    font-weight: bold;
}
.ObjectOption-ButtonImage {
    border: 2px solid var(--color-white);
    overflow: hidden;
    padding: 2px;
}
.ObjectOption-ButtonImage.Button_active {
    border: 2px solid var(--color-light-green);
}
.ObjectOption-ButtonImage .Button-Wrapper {
    overflow: hidden;
    border-radius: 5px;
}
.ObjectOption-Textinput .Textinput-Control {
    color: var(--color-black);
}
.ObjectOption .Select {
    display: block;
}
.ObjectOption-Icon {
    padding: 10px;
}