.FacadeMaterials {
    width: 100%;
    height: 100%;
}
.FacadeMaterials-Wrapper {
    display: grid;
    grid-template-columns: repeat(2, calc(50% - 6px));
    row-gap: 12px;
    column-gap: 12px;
}
.FacadeMaterials-Header {
    height: 71px;
    padding-bottom: 12px;
}
.FacadeMaterials-Title {
    font-size: 20px;
    font-weight: 600;
    color: var(--color-black);
    margin-bottom: 12px;
}
.FacadeMaterials-MetaButton {
    width: 36px;
    height: 24px;
    border-radius: 6px;
}
.FacadeMaterials-Body {
    overflow-y: auto;
    overflow-x: hidden;
    padding: 20px;
    height: 100%;
}
.FacadeMaterials-Body::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background-color: var(--color-white);
}
.FacadeMaterials-Body::-webkit-scrollbar-thumb {
    background-color: var(--color-light-black);
    border-radius: 10px;
}
.FacadeMaterials-Items {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
}
.FacadeMaterials-Item {
    width: 23%;
}
.FacadeMaterials-ChangeGroup {
    margin-bottom: 16px;
}
.FacadeMaterials-ChangeGroupButton {
    border-radius: 8px;
    padding: 8px;
}