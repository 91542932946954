.EditPlinths-Object-Options{
    position: absolute;
    bottom: 10px;
    left: 15px;
    width: calc( 50% - 20px );
    height: max-content;
    border-radius: 8px;
    background-color: var(--color-light-gray);
    z-index: 2;
    padding: 20px;
}

.EditPlinths-Buttons-Container{
    position: absolute;
    top: 60px;
    left: 15px;
    width: max-content;
    height: max-content;
    border-radius: 8px;
    background-color: var(--color-light-gray);
    z-index: 2;
}
.EditPlinths-Button {
    padding: 10px;
    border: 1px solid var(--color-gray);
    border-radius: 8px;
}

.EditPlinths-Button-Add{
    position: absolute;
    bottom: 32vh;
    left: 17vw;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 10px;
}
.EditPlinths-Image-Plinth{
    position: absolute;
    left: 15px;
    top: 60px;
    width: calc( 50% - 20px );
    height: max-content;
    border-radius: 8px;
}
