.Input {
    width: 100%;
    resize: none;
    border: 2px solid var(--color-gray);
    height: 36px;
    padding: 5px 10px;
    border-radius: 8px;
    font-size: 14px;
    color: var(--color-black);
}
.Input_focus {
    outline: none;
}
.Input_error {
    border-color: var(--color-red);
}