.Checkbox {
    display: inline-flex;
    align-items: center;
}
.Checkbox .Checkbox .Checkbox-Box:before {
    border: 2px solid var(--color-light-green);
    border-radius: 2px;
}
.Checkbox_error .Checkbox .Checkbox-Box:before {
    border-color: var(--color-red);
}
.Checkbox .Checkbox .Checkbox-Box {
    flex-shrink: 0;
}
.Checkbox .Checkbox_hovered .Checkbox-Box:before {
    border: none;
}
.Checkbox .Checkbox_checked .Checkbox-Box:before {
    border: none;
}
.Checkbox .Checkbox-Label {
    font-size: 16px;
    font-weight: 500;
    color: var(--color-black);
}
.Checkbox_size_sm .Checkbox-Label {
    font-size: 12px;
}
.Checkbox_color_lightBlack .Checkbox-Label {
    color: var(--color-light-black);
}