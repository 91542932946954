.Wizard-HorizontalMenu {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    column-gap: 20px;
    padding: 0 15px 10px 15px;
    z-index: 1;
}
.WizardHorizontalMenu-Button {
    padding: 8px;
}