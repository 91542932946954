/* Color Picker */
.ColorPicker-Preview{
    width: 120px;
    height: 120px;
    border-radius: 8px;
  }
  
  .ColorPicker-Section .react-colorful {
    width: 120px;
    height: 120px;
  }
  
  .ColorPicker-Section .react-colorful__hue {
    height: 20px;
  }
  
  .ColorPicker{
    width: min-content;
    margin-bottom: 16px;
  }

  .ColorPicker-Section{
    display: flex;
    gap: 16px;
    margin-bottom: 16px;
  }

  .ColorPicker-Input{
    position: relative;
    z-index: 2;
    box-sizing: border-box;
    width: 100%;
    margin: 0;
    padding: 0;
    font-family: var(--textinput-font-family);
    font-size: 100%;
    vertical-align: top;
    border: 0 solid transparent;
    outline: 0;
    background: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);


    background-color: var(--color-white);
    border: 2px solid var(--color-gray);
    height: 36px;
    padding: 5px 10px;
    border-radius: 8px;
    width: 100%;
    min-width: 40px;
  }
  