.CreateOptionGroup {
    display: none;
}
.CreateOptionGroup_visible {
    display: block;
}
.CreateOptionGroup-Options {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
}