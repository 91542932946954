.MaterialItem {
    min-height: 250px;
    padding: 13px 11px;
    border-radius: 12px;
    border: 1px solid var(--color-light-gray);
    background-color: var(--color-white);
    overflow: hidden;
    box-shadow: none;
}
.MaterialItem_animated {
    transition: .4s;
}
.MaterialItem_hover {
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
}
.MaterialItem-Wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    height: 100%;
}
.MaterialItem-Name {
    font-size: var(--font-s-middle);
    color: var(--color-black);
}
.MaterialItem-Images {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    border-radius: 12px;
    border: 1px solid var(--color-gray);
    height: 100%;
}
.MaterialItem-Color,
.MaterialItem-Icon {
    height: 100%;
    object-fit: cover;
    border: 1px solid var(--color-gray);
}
.MaterialItem-Image {
    width: 100%;
    object-fit: cover;
}
.MaterialItem-Color {
    width: 100%;
}

.MaterialItem-Buttons {
    display: flex;
    justify-content: flex-end;
}
.MaterialItem-Button {
    padding: 8px;
    margin-left: 20px;
}
.MaterialItem-Note {
    font-size: var(--font-smaller);
}