.Button {
    cursor: pointer;
    border: none;
    outline: none;
    border-radius: 8px;
}
.Button.Button_disabled {
    cursor: default;
}
.Button-Wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.Button_bkg_white {
    background-color: var(--color-white);
}
.Button_bkg_lightGreen {
    background-color: var(--color-light-green);
}
.Button_bkg_lightOrange {
    background-color: var(--color-light-orange);
}
.Button_bkg_lightRed {
    background-color: var(--color-light-red);
}
.Button_fill_lightBlack svg {
    fill: var(--color-light-black);
}
.Button_fill_lightGray svg {
    fill: var(--color-light-gray);
}
.Button_fill_white svg {
    fill: var(--color-white);
}
.Button-Icon_fill_lightBlack,
.Button-Icon_fill_lightBlack svg {
    fill: var(--color-light-black);
}
.Button-Icon_fill_gray,
.Button-Icon_fill_gray svg {
    fill: var(--color-gray);
}
.Button-Icon_fill_lightRed,
.Button-Icon_fill_lightRed svg {
    fill: var(--color-light-red);
}
.Button-Icon_fill_lightOrange,
.Button-Icon_fill_lightOrange svg {
    fill: var(--color-light-orange);
}
.Button-Icon_fill_lightGreen,
.Button-Icon_fill_lightGreen svg {
    fill: var(--color-light-green);
}
.Button_fill_lightGreen svg {
    fill: var(--color-light-green);
}
.Button_fill_red svg {
    fill: var(--color-red);
}
.Button_color_white {
    color: var(--color-white);
}
.Button_color_lightBlack {
    color: var(--color-light-black);
}
.Button_color_black {
    color: var(--color-black);
}
.Button_color_lightRed {
    color: var(--color-light-red);
}
.Button_color_red {
    color: var(--color-red);
}
.Button_color_lightGreen {
    color: var(--color-light-green);
}
.Button_border_lightGreen {
    border: 1px solid var(--color-light-green);
}
.Button_border_green {
     border: 1px solid var(--color-green);
}
.Button_border_lightGreen {
     border: 1px solid var(--color-green);
}
.Button_border_orange {
     border: 1px solid var(--color-orange);
}
.Button_border_gray {
     border: 1px solid var(--color-gray);
}
.Button_border_lightBlack {
     border: 1px solid var(--color-light-black);
}
.Button_border_black {
     border: 1px solid var(--color-black);
}
.Button_border_lightRed {
     border: 1px solid var(--color-light-red);
}
.Button_border_red {
     border: 1px solid var(--color-red);
}
.Button-Text {
    white-space: nowrap;
}