.RangeNumber {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}
.RangeNumber-Range {
    width: 100%;
}
.RangeNumber .Slider .Slider-Container {
    padding: 0;
}
.RangeNumber-Range .Slider .Slider-Handle .Slider-Thumb {
    transition: none;
}
.RangeNumber-Range .Slider .Slider-Handle .Slider-Thumb:focus {
    box-shadow: 0 0 2px 4px rgba(var(--color-black), 0.38);
}
.RangeNumber-Range .Slider .Slider-Handle .Slider-Thumb:hover {
    box-shadow: 0 0 2px 4px rgba(var(--color-black), 0.38);
}
.RangeNumber-Input {
    width: 100%;
}
.RangeNumber .Number .Textinput-Control {
    padding: 0 20px;
}