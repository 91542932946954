.Accessories-Title {
    font-size: var(--font-middle);
    margin-bottom: 15px;
}
.Accessories-Group {
    padding: 10px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    position: relative;
    margin-bottom: 10px;
    background-color: var(--color-white);
}
.Accessories-GroupHeader {
    display: flex;
}
.Accessories-TumblerUI {
    margin-right: 10px;
}