
.CreateObjectOptions-PriceAmount {
    display: flex;
    justify-content: space-around;
    margin-top: 10px;
}
.CreateObjectOptions-Price,
.CreateObjectOptions-Amount,
.CreateObjectOptions-StockInWay {
    flex-basis: calc(100% / 3);
    text-align: center;
    flex-direction: column;
    display: flex;
    font-size: var(--font-s-middle);
    justify-content: flex-start;
    margin: 10px 0;
    align-items: center;
}
.CreateObjectOptions-PriceTitle,
.CreateObjectOptions-AmountTitle,
.CreateObjectOptions-StockInWayTitle {
    color: var(--color-light-black);
    font-size: var(--font-s-middle);
}
.CreateObjectOptions-PriceValue {
    font-size: var(--font-big);
    font-weight: bold;
    color: var(--color-red);
    padding: 5px 10px;
    text-align: center;
    width: 150px;
}
.CreateObjectOptions-Amount {
    display: flex;
    font-size: var(--font-s-middle);
    justify-content: flex-start;
    margin: 10px 0;
    align-items: center;
}
.CreateObjectOptions-AmountValue {
    color: var(--color-black);
    font-size: var(--font-middle);
    font-weight: bold;
    width: auto;
    padding: 5px 10px;
    text-align: center;
}
.CreateObjectOptions-AmountValue_zero {
    color: var(--color-light-red);
}
.CreateObjectOptions-StockInWayValue {
    padding-top: 3px;
}