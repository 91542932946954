.Wizard {
    width: 100%;
    height: 100%;
}
.Wizard-Editor {
    width: 100vw;
    height: 100vh;
    position: absolute;
    bottom: 0;
    right: 0;
}
.Wizard-UI {
    width: 100%;
    height: 100%;
    min-width: 350px;
    overflow: hidden;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}
.Wizard-CuttingCanvas {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}
.Wizard-LoadingContainer {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.Wizard-Loading {
    font-size: var(--font-middle);
}
.Wizard-Loading_error {
    color: var(--color-red);
}