.WizardBuy {
    position: relative;
    border-radius: 8px;
    background-color: var(--color-white);
    padding: 15px;
}
.WizardBuy-Title {
    font-weight: bold;
    font-size: var(--font-big);
    margin-bottom: 20px;
}
.WizardBuy-Header {
    display: flex;
    margin-bottom: 20px;
}
.WizardBuy-Images {
    display: flex;
    width: 25%;
    flex-direction: column;
}
.WizardBuy-Info {
    width: 75%;
    padding-left: 15px;
}