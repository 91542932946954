.RadioButton {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    padding: 1px;
}
.RadioButton-Button {
    height: 32px;
    padding: 5px 10px;
    border-width: 1px;
    border-style: solid;
    border-radius: 5px;
}
.RadioButton-Button_active,
.RadioButton-Button_hover {
    outline: 1px solid var(--color-red);
}
.RadioButton-Button_type_kitCode {
    min-height: 60px;
    height: auto;
    padding: 0;
    min-width: 100px;
    color: #353952;
}
.RadioButton-Button_type_kitCode.Button_border_green {
    box-shadow: 0 2px 8px 0 var(--color-light-green);
    border: 2px solid var(--color-green);
}
.RadioButton-Button_type_kitCode .Button-Wrapper {
    flex-direction: column;
    row-gap: 5px;
    position: relative;
    padding: 5px;
}
.RadioButton-Button_type_kitCode .Button-Icon_type_left {

}
.RadioButton-Button_type_kitCode .Button-Icon_type_right {
    position: absolute;
    top: 2px;
    right: 5px;
    fill: var(--color-light-green);
}