.IntegratedHandles {
  position: absolute;
  top: 60px;
  right: 15px;
  bottom: 10px;
  width: calc(50% - 20px);
  border-radius: 8px;
  background-color: var(--color-light-gray);
  padding: 20px 10px 20px 20px;
  z-index: 2;
}
.IntegratedHandles-Tumbler{
  margin-bottom: 16px;
}

.IntegratedHandles-Button {
  padding: 10px;
  margin-bottom: 16px;
}

.IntegratedHandles-Box-Input {
    display: flex;
    /* flex-direction: column; */
    gap: 16px;
    max-width: 800px;
}

.IntegratedHandles .Select, .IntegratedHandles-Select {
    width: 100%;
    margin-bottom: 16px;
    display: block;
}

.IntegratedHandles-Textinput{
  margin-bottom: 16px;
}
.IntegratedHandles-Textinput input[type="number"] {
  -webkit-appearance: none;
  appearance: textfield;
  -moz-appearance: textfield;
}

.IntegratedHandles-Textinput input[type="number"]::-webkit-inner-spin-button,
.IntegratedHandles-Textinput input[type="number"]::-webkit-outer-spin-button {
  display: none;
  -webkit-appearance: none;
  margin: 0;
}

.IntegratedHandles-Content{
  padding-right: 16px;
  margin-top: 16px;
}

.IntegratedHandles-Header {
  display: flex;
  padding-bottom: 10px;
  margin: 16px 0 0 0px;
  border-bottom: 2px solid var(--color-light-gray);
  align-items: center;
}

.IntegratedHandles-Title {
  display: flex;
  padding-bottom: 10px;
  margin: 0px;
  align-items: center;
  margin-top: 16px;
}

.IntegratedHandles-Children{
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #bcbcbc;
  border-radius: 8px;
  padding: 6px 16px;
  margin-bottom: 16px;
}

.IntegratedHandles-Box-Btn{
  display: flex;
  gap: 16px;
  justify-content: center;
  align-items: center;
}

.IntegratedHandles-Content .text-danger, .IntegratedHandles-Error{
  color: rgba(246, 62, 62, 0.8);
  margin-bottom: 16px;
}
