.LoadingTextureData {
    position: absolute;
    top: 115px;
    right: 15px;
    z-index: 1010;
    display: none;
}
.LoadingTextureData_visible {
    display: block;
}
.LoadingTextureData-Content {
    width: 180px;
    position: relative;
    text-align: center;
    background-color: var(--color-white);
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--color-black);
    border-radius: 8px;
    border: 1px solid var(--color-light-gray);
    padding: 5px;
    font-size: var(--font-s-middle);
}
.LoadingTextureData-Spin {
    margin-right: 10px;
}