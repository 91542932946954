.cnFormTechnologMap-Button {
    padding: 10px;
    border: 1px solid var(--color-gray);
    border-radius: 8px;
    margin-bottom: 10px;
    margin-right: 30px;
}
.cnFormTechnologMap-Content {
  padding-right: 16px;
  margin-top: 16px;
}

.cnFormTechnologMap-Header {
  display: flex;
  padding-bottom: 10px;
  margin: 16px 0 0 0px;
  border-bottom: 2px solid var(--color-light-gray);
  align-items: center;
}
.cnFormTechnologMap-Title {
  display: flex;
  padding-bottom: 10px;
  margin: 0px;
  align-items: center;
  margin-top: 16px;
}
.cnFormTechnologMap-Select {
  width: 100%;
  margin-bottom: 16px;
  display: block;
}
.cnFormTechnologMap-Success-Message {
  color: var(--color-green);
}
.cnFormTechnologMap-Textinput {
  margin-bottom: 16px;
}
.cnFormTechnologMap-Textinput input[type="number"] {
  -webkit-appearance: none;
  appearance: textfield;
  -moz-appearance: textfield;
}

.cnFormTechnologMap-Textinput input[type="number"]::-webkit-inner-spin-button,
.cnFormTechnologMap-Textinput input[type="number"]::-webkit-outer-spin-button {
  display: none;
  -webkit-appearance: none;
  margin: 0;
}
.cnFormTechnologMap-Box-Input {
  display: flex;
  gap: 16px;
  max-width: 700px;
}
.cnFormTechnologMap-Tumbler {
  margin-bottom: 16px;
}
.cnFormTechnologMap-Error{
  color: rgba(246, 62, 62, 0.8);
  margin-bottom: 16px;
}
