.WizardEditCollection-Slider {
    width: 48%;
    padding: 15px;
    border-radius: 15px;
    background-color: var(--color-light-gray);
    max-width: 600px;
}
.WizardEditCollection {
    position: absolute;
    top: 60px;
    right: 15px;
    bottom: 10px;
    width: calc(100% - 440px);
    border-radius: 8px;
    background-color: var(--color-light-gray);
    z-index: 2;
    padding: 20px 10px 20px 20px;
    overflow: hidden;
}
.WizardEditCollection-Container {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    position: relative;
    display: flex;
}
.WizardEditCollection-Materials{
    position: absolute;
    top: 60px;
    right: 15px;
    bottom: 10px;
    width: calc(100% - 30px);
    height: fit-content;
    min-height: 100%;
    border-radius: 8px;
    background-color: var(--color-light-gray);
    z-index: 2;
    padding: 20px 10px 20px 20px;
}
.WizardEditCollection-Container > * {
    flex: 1; 
}
.WizardEditCollection-Content {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    padding-right: 10px;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
}
.WizardEditCollection-Buttons {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 0 20px 10px 20px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.WizardEditCollection-Materials-Groups{
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    padding-bottom: 16px;
}
.WizardEditCollection-Button {
    padding: 10px;
    border: 1px solid var(--color-gray);
    border-radius: 8px;
}
/* @media screen  and (max-width: 1680px) {
    .WizardEditCollection {
        width: 360px;
    }
} */

.WizardEditCollection-StepItem {
    border-radius: 8px;
    background-color: var(--color-white);
    padding: 10px;
    margin-bottom: 20px;
}
.WizardEditCollection-StepHeader {
    display: flex;
    font-size: var(--font-middle);
    font-weight: bold;
    padding: 10px 0;
    cursor: pointer;
}
.WizardEditCollection-StepItem_active .WizardEditCollection-StepHeader {
    border-bottom: 1px solid var(--color-light-gray);
    margin-bottom: 10px;
}
.WizardEditCollection-StepContent {
    margin-bottom: 10px;
    display: none;
}
.WizardEditCollection-StepItem_active .WizardEditCollection-StepContent {
    display: block;
}
.WizardEditCollection-StepButtons {
    justify-content: space-between;
    align-items: center;
    display: none;
}
.WizardEditCollection-StepItem_active .WizardEditCollection-StepButtons {
    display: flex;
}
.WizardEditCollection-StepArrow {
    margin-left: auto;
}
.WizardEditCollection-StepItem_active .WizardEditCollection-StepArrow {
    transform: rotate(225deg);
}
.WizardEditCollection_step_services,
.WizardEditCollection_step_spec,
.WizardEditCollection_step_buy {
    right: 15px;
    width: auto;
    z-index: 100;
    bottom: 60px;
}
@media screen  and (max-width: 767px) {
    .WizardEditCollection_step_buy {
        bottom: 10px;
    }
    .WizardEditCollection-Buttons {
        display: none;
    }
    .WizardEditCollection-Container {
        padding-bottom: 20px;
    }
}
.WizardEditCollection_step_modules .WizardEditCollection-Content {
    height: 100%;
    overflow: hidden;
}
.WizardEditCollection_hidden .WizardEditCollection-Content {
    overflow: inherit;
}