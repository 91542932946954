.AccessoriesGroup {

}
.AccessoriesGroup-Header {
    display: flex;
    align-items: center;
}
.AccessoriesGroup-TumblerUI {
    margin-right: 10px;
}
.AccessoriesGroup-Body {
    width: 100%;
    margin-top: 10px;
}