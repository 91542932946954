.CollectionDetail {
    display: flex;
    flex-wrap: wrap;
}
.CollectionDetail-Breadcrumbs {
    margin-bottom: 20px;
}
.CollectionDetail-Slider,
.CollectionDetail-Info {
    width: 48%;
    padding: 15px;
    border-radius: 15px;
    background-color: var(--color-light-gray);
}
.CollectionDetail-Slider {
    margin-right: 2%;
}
.CollectionDetail-Info {
    margin-left: 2%;
}
.CollectionDetail-FlexBlock {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
}
.CollectionDetail-Name {
    font-size: var(--space-l);
    font-weight: bold;
    margin-bottom: 10px;
}
.CollectionDetail-Price {
    font-size: var(--space-l);
    margin-bottom: 10px;
}
.CollectionDetail-Buttons {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 220px;
    margin-left: auto;
}
.CollectionDetail-Button {
    min-width: 220px;
    font-size: 14px;
    font-weight: 400;
    border-width: 1px;
    border-style: solid;
    border-radius: 8px;
    padding: 10px;
    margin-bottom: 10px;
}
.CollectionDetail-Section {
    margin-bottom: 16px;
}
.CollectionDetail-Notes {

}
.CollectionDetail-Notes_color_red {
    color: var(--color-red);
}
.CollectionDetail-Subtitle {
    font-size: var(--space-l);
    margin-bottom: 10px;
    font-weight: bold;
}
.CollectionDetail-Projects {
    margin-top: 20px;
    width: 100%;
}
.CollectionDetail-ProjectsItems {
    display: flex;
    flex-wrap: wrap;
}
.CollectionDetail-Project {
    margin: 10px auto 10px 20px;
    width: 30%;
}
/*.CollectionDetail-Project:last-child {*/
/*    margin-left: 0;*/
/*}*/
@media screen  and (max-width: 767px) {
    .CollectionDetail-Slider,
    .CollectionDetail-Info {
        width: 100%;
    }
    .CollectionDetail-Slider {
        margin-right: 0;
    }
    .CollectionDetail-Info {
        margin-left: 0;
    }
    .CollectionDetail-Project {
        width: 50%;
    }
}
@media screen  and (max-width: 480px) {
    .CollectionDetail-Project {
        width: 100%;
    }
}