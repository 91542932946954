.Wizard-VerticalMenu {
    position: absolute;
    width: 40px;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    min-height: 100px;
    background-color: var(--color-white);
    border: 1px solid var(--color-gray);
    border-radius: 8px;
}
.WizardVerticalMenu-Action {
    padding: 9px;
    cursor: pointer;
    position: relative;
}
.WizardVerticalMenu-Icon {
    width: 20px;
    height: 20px;
}
.WizardVerticalMenu-Icon_active {
    fill: var(--color-red);
}
.WizardVerticalMenu-Popup {
    white-space: nowrap;
}
.WizardVerticalMenu-SetProjectOwner {
    min-width: 500px;
    min-height: 400px;
}
.WizardVerticalMenu-ModalTitle {
    font-size: var(--font-s-middle);
    font-weight: bold;
    margin-bottom: 20px;
}
.WizardVerticalMenu-Link {
    text-decoration: underline;
    font-weight: bold;
}