.LoadingApiData {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1001;
    display: none;
}
.LoadingApiData_visible {
    display: block;
}
.LoadingApiData-Content {
    width: 100%;
    height: 100%;
    position: relative;
    background-color: rgba(0,0,0,0.5);
}
.LoadingApiData-Spin {
    position: absolute;
    left: calc(50% - 16px);
    top: calc(50% - 16px);
}