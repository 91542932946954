.RoomParams-Accordion {
    margin-bottom: 20px;
}
.RoomParams-Title {
    margin-bottom: 10px;
    padding-left: 10px;
}

.RoomParams-Decor {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    }
.RoomParams-SizeItem {
    margin-bottom: 10px;
}
.RoomParams-ConstructiveLeftList {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
}
.RoomParams-Constructive {
    border-radius: 8px;
    border: 2px solid var(--color-gray);
    background-color: var(--color-light-gray);
    width: 48%;
    margin: 3px auto;
    display: flex;
    flex-direction: column;
}