.SettingsMenu {
    width: 100%;
    height: 100%;
    padding: 15px 5px 15px 15px;
    background-color: var(--color-light-gray);
}
.SettingsMenu-CloseButton {
    position: absolute;
    width: 30px;
    top: 0;
    right: -40px;
    height: 30px;
    padding: 5px;
}
.SettingsMenu-Body {
    overflow-y: auto;
    overflow-x: hidden;
    margin-top: 0;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding-right: 10px;
}
.SettingsMenu-Body::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background-color: var(--color-white);
}
.SettingsMenu-Body::-webkit-scrollbar-thumb {
    background-color: var(--color-gray);
    border-radius: 10px;
}
.SettingsMenu-Header {
    display: flex;
    justify-content: flex-start;
    column-gap: 10px;
    margin-bottom: 10px;
    background-color: var(--color-white);
    padding: 5px;
    border-radius: 8px;
    align-items: center;
}
.SettingsMenu-Images {
    width: 90px;
    flex-shrink: 0;
}
.SettingsMenu-Image,
.SettingsMenu-Icon {
    width: 100%;
}
.SettingsMenu-Name {
    font-size: var(--font-s-middle);
    font-weight: bold;
    color: var(--color-black);
    margin-bottom: 10px;
}
.SettingsMenu-Price {
    width: 181px;
    display: flex;
    justify-content: space-between;
}
.SettingsMenu-PriceTitle,
.SettingsMenu-PriceValue {
    font-size: 18px;
    font-weight: 600;
    color: var(--color-black);
}
.SettingsMenu-Sizes {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
}
.SettingsMenu-SizesItem {
    display: flex;
    justify-content: space-between;
}
.SettingsMenu-SizesTitle {
    font-size: 14px;
    font-weight: 400;
    color: var(--color-light-black);
}
.SettingsMenu-SizesValue {
    font-size: 14px;
    font-weight: 400;
    color: var(--color-black);
}
.SettingsMenu-Wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 15px;
}
.SettingsMenu-Item {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
}
.SettingsMenu-ItemTitle {
    font-size: 16px;
    font-weight: 600;
    color: var(--color-black);
}
.SettingsMenu-General {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
}
.SettingsMenu-TumblerItem,
.SettingsMenu-Option {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.SettingsMenu-TumblerTitle,
.SettingsMenu-OptionTitle {
    font-size: 14px;
    font-weight: 400;
    color: var(--color-black);
}
.SettingsMenu-OptionValue.ObjectOption-Value {
    width: auto;
    margin-left: auto;
}
.SettingsMenu-Facades,
.SettingsMenu-Aprons {
    display: flex;
    flex-direction: column;
    row-gap: 24px;
}
.SettingsMenu-TumblerUI {
    margin-bottom: 8px;
}
.SettingsMenu-FacadeSideType {
    margin-top: 10px;
}
.SettingsMenu-EquipmentItem {
    border: 1px solid var(--color-light-gray);
    border-radius: 12px;
    background-color: var(--color-white);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 20px 24px;
    position: relative;
    margin-bottom: 16px;
    flex-direction: column;
}
.SettingsMenu-Title {
    width: 100%;
    margin-bottom: 10px;
}
.SettingsMenu-Value {
    width: 100%;
}