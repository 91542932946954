.UserObjectSelectAdd {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
}
.UserObjectSelectAdd-Error {
    color: var(--color-red);
    padding: 5px;
}
.UserObjectSelectAdd-Input_error {
    border: 2px solid var(--color-red);
}