.CreateCommonObject {
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 13px 11px;
    border-radius: 12px;
    border: 1px solid var(--color-gray);
    background-color: var(--color-light-gray);
    overflow: hidden;
    margin-bottom: 20px;
}
.CreateCommonObject_animated {
    transition: .4s;
}
.CreateCommonObject-Name {
    font-weight: bold;
    margin-bottom: auto;
}
.CreateCommonObject-Images {
    max-width: 130px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
}
.CreateCommonObject-Image {
    max-width: 100%;
}
.CreateCommonObject-Images_grab {
    cursor: grab;
}
.CreateCommonObject-Images_grab::after {
    content: attr(data-content);
    position: absolute;
    bottom: 50%;
    transform: translateY(50%);
    left: 0;
    right: 0;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    color: var(--color-green);
    background-color: var(--color-white);
    border-radius: 8px;
    opacity: 0.5;
    text-align: center;
}
.CreateCommonObject-Image,
.CreateCommonObject-Icon {
    height: 100%;
    object-fit: cover;
}
.CreateCommonObject-Icon {
    fill: var(--color-black);
}
.CreateCommonObject-Button {
    border-radius: 8px;
    padding: 8px;
    font-size: var(--font-smaller);
}
.CreateCommonObject-Hidden {
    display: flex;
    flex-direction: column;
    row-gap: 12px;
}
.CreateCommonObject-Descr {
    font-weight: 500;
    color: var(--color-black);
}
.CreateCommonObject-Widths {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.CreateCommonObject-WidthsTitle {
    font-size: 12px;
    font-weight: 500;
    line-height: 17px;
    color: var(--color-black);
}
.CreateCommonObject-Buttons {
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
}
.CreateCommonObject-Equipment-Buttons{
    display: flex;
    margin-top: 10px;
    justify-content: space-between;
}