.Auth {
    display: flex;
    align-items: center;
}
.Auth-User {
    display: flex;
    align-items: center;
}
.Auth-User_hover {
    cursor: pointer;
}
.Auth-Icon {
    fill: var(--color-black);
}
.Auth-Text {
    font-size: 16px;
    color: var(--color-light-black);
    white-space: nowrap;
}