.Breadcrumbs {
    display: flex;
    align-items: center;
    column-gap: 10px;
}
.Breadcrumbs-Item {
    display: flex;
    align-items: center;
    column-gap: 10px;
}
.Breadcrumbs-Icon {
    fill: var(--color-light-black);
}
.Breadcrumbs-Link {
    font-size: 13px;
    font-weight: 400;
}
.Breadcrumbs-Text {
    font-size: 13px;
    font-weight: 400;
    color: var(--color-black);
}