.Modal {
    overflow-y: auto;
}
.ModalUI-CloseButton {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 10px;
    z-index: 1;
}
.ModalUI-Wrapper {
    border-radius: 8px;
    padding: 50px 20px 30px;
    overflow: hidden;
    background-color: var(--color-white);
    box-shadow: 0 0 8px var(--color-light-black);
    position: relative;
}
.ModalUI-Wrapper_size_full {
    width: 90vw;
    height: 90vh;
}
.ModalUI .Modal-Content {
    overflow: hidden;
    border: 1px solid var(--color-gray);
}
.ModalUI.Modal_theme_normal .Modal-Overlay {
    background-color: rgba(255,255,255,0.5);
    filter: blur(5px);
}
.ModalUI.Modal_theme_normal .Modal-Content {
    margin: 0;
    border-radius: 8px;
    position: relative;
    border: 1px solid #ccd7e7;
    overflow: auto;
    display: inline-block;
    text-align: left;
}