.ImageZoom {
    position: absolute;
    right: 5px;
    top: 5px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 2px solid var(--color-light-gray);
    background-color: var(--color-white);
    cursor: pointer;
    padding: 5px;
}
.ImageZoom-Icon {
    width: 15px;
    height: 15px;
}
.ImageZoom-ImageContainer {
    overflow: auto;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.ImageZoom-Image {
    width: 100%;
    height: auto;
    margin: auto;
}