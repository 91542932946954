.AmoForm {
    width: 500px;
    display: flex;
    flex-direction: column;
    row-gap: 15px;
}
.AmoForm-Header {
    font-size: 19px;
    font-weight: 700;
    color: var(--color-black);
}
.AmoForm-Input_stretch {
    height: 80px;
}
.AmoForm-Checkbox {
    font-size: 16px;
    font-weight: 400;
    color: var(--color-light-black);
}
.AmoForm-Line {
    border: 0;
    border-top: 1px solid var(--color-gray);
}
.AmoForm-Notes {
    font-size: 16px;
    font-weight: 400;
    color: var(--color-black);
}
.AmoForm-Errors {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
}
.AmoForm-Error {
    font-size: 14px;
    font-weight: 500;
    color: var(--color-red);
}
.AmoForm-Button {
    align-self: center;
    font-size: 14px;
    font-weight: 400;
    border-width: 1px;
    border-style: solid;
    border-radius: 8px;
    padding: 10px;
}