.Property {
    display: flex;
    border-bottom: 1px solid var(--color-f-0-f-0-f-0);
    margin-top: 5px;
    min-height: 25px;
}
.Property-Title {
    width: 50%;
    font-weight: bold;
}
.Property-Value {
    width: 50%;
}