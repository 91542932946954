.CatalogItem {
    border-radius: 8px;
    border: 2px solid var(--color-light-gray);
    padding: 20px;
    overflow: hidden;
    background-color: var(--color-white);
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
}
.CatalogItem-Link {
    display: block;
}
.CatalogItem-Link_textual {
    font-size: 16px;
    font-weight: 400;
    color: var(--color-black);
    border-bottom: 1px dashed;
}
.CatalogItem-Images {
    height: 216px;
    text-align: center;
    background-color: var(--color-light-gray);
    display: flex;
    justify-content: center;
    width: 100%;
    border: 2px solid var(--color-gray);
    border-radius: 5px;
    align-items: center;
    padding: 5px;
}
.CatalogItem-Image {
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.CatalogItem-Content {
    padding: 5px;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}
.CatalogItem-Info_hidden {
    max-height: 130px;
    overflow: hidden;
}
.CatalogItem-Title {
    font-size: 17px;
    font-weight: 700;
}
.CatalogItem-Price {
    font-size: 17px;
    font-weight: 400;
    color: var(--color-light-black);
    margin-bottom: 16px;
}
.CatalogItem-Error {
    font-size: 15px;
    font-weight: 700;
    color: var(--color-red);
}
.CatalogItem-Description {
    font-size: 16px;
    font-weight: 400;
}
.CatalogItem-More {
    font-size: 16px;
    font-weight: 400;
    border-bottom: 1px dashed;
    cursor: pointer;
    color: var(--color-gray);
    align-self: flex-end;
    margin-bottom: 10px;
}
.CatalogItem-Actions {
    margin-top: auto;
    padding-top: 10px;
    margin-bottom: 0;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}
.CatalogItem-Buttons {
    display: flex;
    column-gap: 6px;
    flex-wrap: wrap;
}
.CatalogItem-Button {
    font-size: 14px;
    font-weight: 400;
    border-width: 1px;
    border-style: solid;
    border-radius: 8px;
    padding: 10px;
    margin-bottom: 10px;
}
