.Link {
    display: inline-flex;
    cursor: pointer;
}
.Link_color_lightBlack {
    color: var(--color-light-black);
}
.Link_color_black {
    color: var(--color-black);
}
.Link_color_red {
    color: var(--color-red);
}
.Link_fill_red {
    fill: var(--color-red);
}
.Link_fill_black {
    fill: var(--color-black);
}
.Link_fill_lightBlack {
    fill: var(--color-light-black);
}
.Link-Wrapper {
    display: flex;
    align-items: center;
}
.Link-Text {
    white-space: nowrap;
}
.Link-Children {
    display: flex;
    align-items: center;
    margin-left: auto;
    width: auto;
}
.Link-Children_stretch {
    width: 100%;
}