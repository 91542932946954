.ModulesCatalogFilter {
    margin-left: auto;
    width: 100%;
    display: flex;
    align-items: flex-start;
}
.ModulesCatalogFilter-Title {
    display: flex;
    width: 100%;
    height: 30px;
    overflow: hidden;
}
.ModulesCatalogFilter-Facade {
    font-size: var(--font-smaller);
    width: 50%;
    padding: 0 5px;
}
.ModulesCatalogFilter-FacadeValue {
    font-weight: bold;
}
.ModulesCatalogFilter-ButtonFilter {
    padding: 5px;
}
.ModulesCatalogFilter-Modal {
    padding: 15px;
    min-width: 300px;
}
.ModulesCatalogFilter-SelectFacade {
    display: flex;
    gap: 5px;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 10px;
}
.ModulesCatalogFilter-Select {
    margin-left: auto;
}
.ModulesCatalogFilter-Button {
    padding: 8px;
    margin-left: auto;
}
.ModulesCatalogFilter-ModalButtons {
    display: flex;
}