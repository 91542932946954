.ObjectSelect-Select {
    display: flex;
    margin-bottom: 10px;
    cursor: pointer;
    position: relative;
    width: 100%;
    text-align: center;
    height: 100%;
}
.ObjectSelectSelect-PlusIcon {
    position: absolute;
    left: -8px;
    top: -8px;
    border: 2px solid var(--color-green);
    box-shadow: 0 0 3px var(--color-light-green);
    border-radius: 50%;
    padding: 3px;
    background-color: var(--color-white);
    animation: shadowIcon 2s cubic-bezier(0.64, 0.28, 0.02, 0.96) infinite;
    width: 20px;
    height: 20px;
    fill: var(--color-light-black);
}
.ObjectSelectSelect-Images {
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    overflow: hidden;
    background-color: var(--color-light-gray);
    padding: 3px;
    border: 1px solid var(--color-gray);
}
.ObjectSelectSelect-Image {
    text-align: center;
}
.ObjectSelectSelect-Icon {
    padding: 5px;
}
.ObjectSelectSelect-Texts {
    margin-left: 10px;
    font-size: 15px;
    width: 68%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
}
.ObjectSelectSelect-Title {
    color: var(--color-light-black);
}
.ObjectSelectSelect-Description {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
