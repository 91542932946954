.CreateObjectGroup {
    margin-bottom: 10px;
    background-color: #fff;
    cursor: pointer;
    align-items: center;
    padding: 5px;
    border-radius: 8px;
    display: flex;
    flex-flow: row wrap;
    position: relative;
}
.CreateObjectGroup-Empty {
    font-size: 14px;
    font-weight: 400;
    color: #323232;
}
.CreateObjectGroup-Groups {
    display: grid;
    grid-template-columns: repeat(2, calc(50% - 6px));
    column-gap: 12px;
}
.CreateObjectGroup-LeftGroup,
.CreateObjectGroup-RightGroup {
    display: flex;
    flex-direction: column;
    row-gap: 12px;
}
.CreateObjectGroup-GroupIcon {
    width: 35px;
    height: 35px;
}
.CreateObjectGroup-GroupName {
    margin-left: 10px;
}
.CreateObjectGroup-Arrow {
    position: absolute;
    top: 14px;
    right: 10px;
    width: 16px;
    height: 16px;
    fill: var(--color-light-black)
}
.CreateObjectGroup-Header {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}
.CreateObjectGroup-Items {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
}
.CreateObjectGroup-Item {
    border-radius: 8px;
    border: 2px solid var(--color-gray);
    background-color: var(--color-light-gray);
    width: 48%;
    margin: 3px auto;
    display: flex;
    flex-direction: column;
}