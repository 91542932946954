.WizardServices {
    background-color: var(--color-white);
    min-height: 100%;
    border-radius: 8px;
    padding: 15px;
}
.WizardServices-AllServices {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}
.WizardServices-Auto {
    margin-bottom: 20px;
}
.WizardServices-TumblerUI {
    margin-right: 20px;
}
.WizardServices-TumblerTitle {
    font-weight: bold;
    font-size: var(--font-middle);
}
.WizardServices-Items {
    width: 100%;
    display: block;
    overflow: hidden;
    border-radius: 8px;
    background-color: var(--color-light-gray);
    padding: 15px;
}
.WizardServices-Item {
    margin-bottom: 10px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    border-radius: 8px;
    background-color: var(--color-white);
    padding: 5px 10px;
    width: 100%;
    align-items: center;
}
.WizardServices-Item_header {
    min-height: 30px;
    padding: 0 10px;
    background-color: transparent;
}
.WizardServices-Cell {
    width: 5%;
}
.WizardServices-Cell_type_index {
    text-align: center;
}
.WizardServices-Cell_header {

}
.WizardServices-Cell_type_vendorCode {
    width: 10%;
}
.WizardServices-Cell_type_name {
    width: 50%;
}
.WizardServices-Cell_type_autoCalculate {
    width: 10%;
}
.WizardServices-Cell_type_count {
    width: 10%;
}
.WizardServices-Cell_type_sum {
    width: 10%;
}
.WizardServices-Cell .RangeNumber {
    margin-right: 15px;
    width: auto;
}
.WizardServices-Total {
    display: flex;
}
.WizardServices-TotalTitle {
    font-size: var(--font-big);
    font-weight: bold;
    margin-left: auto;
}
.WizardServices-TotalValue {
    font-size: var(--font-big);
    font-weight: bold;
    width: 10%;
    margin-left: 20px;
    text-align: right;
}