.Page {
    position: relative;
}
.Page-Wrapper {
    padding: 80px 20px 0 20px;
}
.Page-Sections {
    align-items: center;
}
.Page-Title {
    font-size: 21px;
    font-weight: 700;
    color: var(--color-black);
    grid-column-start: 1;
    grid-column-end: 3;
    margin-bottom: 30px;
}
.Page-Breadcrumbs {
    margin-bottom: 16px;
}
.Page-Divider {
    border-bottom: 1px solid var(--color-light-gray);
    margin: 0 15px;
}